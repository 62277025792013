<ng-container *ngIf="platformIsBrowser">
  <div class="header">
    @if (review.thumbnails && review.thumbnails.length > 0) {
      <div class="thumb" [style.background-image]="'url('+review.thumbnails[0]+')'" (click)="showImage(review.title, review.images[0])"></div>
    }
    <div class="heading">
      <h3>
        {{review.title}}
      </h3>
      <div class="title">
        <a [attr.href]="review.customerLink"  target="_blank" [ngClass]="{'nolink': !review.customerLink}">{{review.customerName}}</a>
      </div>
      <div class="stars" *ngIf="!review.isAggregate">
        <span>
          {{review.createdAt | date:'M/d/yy @ h:mma' }}
        </span>
        @for (x of starsAsArray; track x) {
          <mat-icon svgIcon="star"></mat-icon>
        }
      </div>
    </div>
  </div>
  <a class="content" href="{{review.link}}" target="_blank">
    {{ review.comments}}
  </a>
  @if (review.thumbnails && review.thumbnails.length > 0) {
  <div class="thumbs">
    @for (thumb of review.thumbnails.slice(1); track thumb; let idx = $index) {
      <div class="thumb" [style.background-image]="'url('+thumb+')'" (click)="showImage(review.title, review.images[idx+1])"></div>
    }
  </div>
  }
  <div class="customer-name" *ngIf="!review.isAggregate && customerDisplayStyle == 'text'">
    <a href="{{review.link}}" target="_blank">{{review.customerName}}</a>
    on
    <a href="{{review.link}}" target="_blank">TrustPilot.com</a>
  </div>

  <div class="overview" *ngIf="review.isAggregate">
    <strong>{{review.rating}}/{{review.maxRating}}</strong>&nbsp;<a href="{{review.link}}" target="_blank">Rating</a><br />
      <div class="stars">
        @for (x of [1,2,3,4,5]; track x) {
          <mat-icon svgIcon="star"></mat-icon>
        }
      </div>
    <strong>{{review.totalReviews}}</strong>&nbsp;<a href="{{review.link}}" target="_blank">Reviews</a>
  </div>

</ng-container>
