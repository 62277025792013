import {Component, DoCheck, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {CartItem} from "../cart-item";

@Component({
  selector: 'mbnm-cart-item-part',
  templateUrl: './cart-item-part.component.html',
  styleUrls: ['./cart-item-part.component.scss']
})
export class CartItemPartComponent implements OnChanges, DoCheck {

  @Input()
  cartItem: CartItem;

  @Input()
  format: string;

  @Input()
  hideBullet: boolean = false;

  @Input()
  oldContentOnly: boolean = false;

  @Input()
  columnTitle: string = null;

  properties: any[];
  valuesToShow: string[] = [];
  isNew: boolean = false;

  ngDoCheck() {
    if (this.cartItem) {
      this.valuesToShow = [];
      for (const prop of this.properties) {
        let currentValue = this.value(prop, 'current');
        let oldValue = this.value(prop, 'old');
        if (!Array.isArray(currentValue)) currentValue = [currentValue];
        if (!Array.isArray(oldValue)) oldValue = [oldValue];
        for (let idx in currentValue) {
          if (currentValue[idx] !== oldValue[idx] && typeof oldValue[idx] !== 'undefined' && oldValue !== null && this.valuesToShow.indexOf('old') == -1) this.valuesToShow.push('old');
          if (!this.oldContentOnly && currentValue[idx] && this.valuesToShow.indexOf('current') == -1) this.valuesToShow.push('current');
          this.isNew = this.isNew || (this.cartItem.lastVersion && currentValue[idx] && !oldValue[idx] ? true : false);
        }
      }
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.format && changes.format.currentValue) {
      this.properties = [];
      const flattenMatches = (matches) => {
        const shift = (m) => {
          m.shift();
          return m;
        }
        return [...matches].map(shift).flat();
      };

      const tokens = flattenMatches(changes.format.currentValue.matchAll(/\<(.+?)\>/g));
      if (!tokens) throw new Error('Bad formatting: ' + changes.format.currentValue);
      for (const token of tokens) {
        let propNameParts = flattenMatches(token.matchAll(/\{(.+?)\}/g));
        if (propNameParts == null) {
          propNameParts = ['id']; // HACK: allows text not tied to a property
        }
        propNameParts = propNameParts[0].split(':');
        this.properties.push({
          name: propNameParts[0],
          valueFilter: propNameParts[1] ? propNameParts[1] : null,
          formatToken: token
        });
      }
    }
  }

  private findPropValues(propStr:string):any {
    const lastVersion = this.cartItem.lastVersion;
    let prop = this.cartItem;
    let lastProp = lastVersion;
    const props = propStr.split('.');
    props.forEach( propName => {
      let isArray = propName.indexOf('[') >= 0;
      let idx:number = -1;
      if (isArray) {
        const propSplit = propName.split('[');
        propName = propSplit[0];
        idx = parseInt(propSplit[1].replace(']',''));
      }
      prop = prop ? prop[propName] : undefined;
      lastProp = lastProp ? lastProp[propName] : undefined;
      if (idx >= 0) {
        prop = prop ? prop[idx] : undefined;
        lastProp = lastProp ? lastProp[idx] : undefined;
      }
    });

    return {
      oldValue: lastProp,
      currentValue: prop
    }
  }

  public value(prop: any, which: string): any {
    let values = this.findPropValues(prop.name);
    if (prop.valueFilter === 'size' || prop.valueFilter === 'currency') {
      values.currentValue = this.parseFloat(values.currentValue);
      values.oldValue = this.parseFloat(values.oldValue);
    }
    return values[which + 'Value'];
  }

  private parseFloat(value: any) {
    const parts = Array.isArray(value) ? value : [value];
    for (let idx in parts) {
      parts[idx] = isNaN(parseFloat(parts[idx])) ? 0 : parseFloat(parts[idx]);
    }
    return parts.length === 1 ? parts[0] : parts;
  }

  public applyFormat(str: string, val: string): string {
    return str
      .replace(/_/g, '<br />')
      .replace(/\*(.*)\*/g, '<strong>$1</strong>')
      .replace(/\{.+?\}/g, val);
  }
}
