import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {StateManagerService} from './state-manager.service';
import {lastValueFrom, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Injectable()
export class CmsService {

  baseUrl = environment.baseUrls().api + 'cms/';
  constructor(private httpClient: HttpClient,
              private stateManager: StateManagerService) {
    this.stateManager.setPrefix('CMS');
  }

  async posts(page: number): Promise<any> {
    const o = this.stateManager.getStateKeyValue('posts-' + page);
    if (this.stateManager.hasValue(o.val)) { return o.val; }
    const result = await lastValueFrom(this.httpClient.get(this.baseUrl + 'posts?page=' + (page ? page : 1)));
    this.stateManager.setStateValue(o.key, result);
    return result;
  }

  async postDetails(slug: string): Promise<any> {
    const o = this.stateManager.getStateKeyValue('postDetails-' + slug);
    if (this.stateManager.hasValue(o.val)) { return o.val; }
    const result  = await lastValueFrom(this.httpClient.get(this.baseUrl + 'post?slug=' + slug));
    this.stateManager.setStateValue(o.key, result);
    return result;
  }

  async pageDetails(slug: string): Promise<any> {
    const o = this.stateManager.getStateKeyValue('pageDetails-' + slug);
    if (this.stateManager.hasValue(o.val)) { return o.val; }
    const result = await lastValueFrom(this.httpClient.get(this.baseUrl + 'page?shallow=true&slug=' + slug));
    this.stateManager.setStateValue(o.key, result);
    return result;
  }

  async pageChildren(slug: string, imageLinkPrefix=""): Promise<any> {
    const o = this.stateManager.getStateKeyValue('pageChildren-' + slug);
    if (this.stateManager.hasValue(o.val)) { return o.val; }
    const result = await lastValueFrom(this.httpClient.get(this.baseUrl + 'page?slug=' + slug + '&imageLinkPrefix=' + imageLinkPrefix));
    this.stateManager.setStateValue(o.key, result)
    return result;
  }

  async merchData(productGroup: string): Promise<any> {
    const o = this.stateManager.getStateKeyValue('merchData-' + productGroup);
    if (this.stateManager.hasValue(o.val)) { return o.val; }
    const result = await lastValueFrom(this.httpClient.get(this.baseUrl + 'products?category=' + productGroup));
    this.stateManager.setStateValue(o.key, result);
    return result;
  }

  async menu(name: string = 'primary'): Promise<any> {
    const o = this.stateManager.getStateKeyValue('menu-' + name);
    if (this.stateManager.hasValue(o.val)) { return o.val; }
    const result = await lastValueFrom(this.httpClient.get(this.baseUrl + 'menu/' + name));
    this.stateManager.setStateValue(o.key, result);
    return result;
  }

  async getDynamicTextBlocks(): Promise<any> {
    const o = this.stateManager.getStateKeyValue('dynamicTextBlocks');
    if (this.stateManager.hasValue(o.val)) { return o.val; }
    const result = await lastValueFrom(
      this.httpClient
        .get(this.baseUrl + 'dynamicTextBlocks')
        .pipe(
          map((dynamicText: any) => {
            return dynamicText;
          })
        )
    );
    this.stateManager.setStateValue(o.key, result);
    return result;
  }
}
