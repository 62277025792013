<div class="grid
            fx-layout-column fx-layout-row--gt-xs fx-layout-wrap--gt-xs
            fx-align--start-x fx-align--x-start
            fx-gap--0px fx-gap--8px--xs
            ">
  <h1 *ngIf="title">{{ title }}</h1>
  @for (tile of shopData; track tile; let i = $index) {
  <div class="grid-tile"
       [ngClass]="{
          'fx-flex--33': i === 1 && highlightPrimary || maxTiles % 3 === 0,
          'fx-flex--50': i !== 0 || !highlightPrimary,
          'fx-flex--66': i === 0 && highlightPrimary
       }">
    <div class="inner" [routerLink]="tile.link"
         [title]="tile.title">
      <img [src]="tile.image" [alt]="tile.title" />
      <h2 [routerLink]="tile.link">
        {{ tile.title}}
      </h2>
      <div *ngIf="tile.content" [innerHtml]="tile.content | sanitizer:'html'" (click)="contentClicked($event)">

      </div>
    </div>
  </div>
  }
</div>

<mat-spinner color="secondary" *ngIf="!shopData"></mat-spinner>
