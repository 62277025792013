<!-- static item -->
<ng-container *ngIf="cartItem.description">
  @for (desc of splitDescription(cartItem.description); track desc) {
  <div>
    <mbnm-cart-item-part [cartItem]="cartItem"
                         [format]="'<' + desc + '>'">
    </mbnm-cart-item-part>
  </div>
  }
</ng-container>

<!-- samples -->
<div *ngIf="samples && samples.length > 0" class="samples-description">
  @for (sample of samples; track sample) {
  <div>
    <div class="heading" *ngIf="sample.groupName">{{ sample.groupName }}
      <ng-container *ngIf="sample.groupNameDescription">{{ sample.groupNameDescription }}</ng-container>
    </div>
    <div>
      <mat-icon class="icon" svgIcon="bullet-point-circle"></mat-icon>
      <ng-container *ngIf="sample.type === 'MAT'">
        <span [innerHtml]="'<strong>' + sample.description + '</strong> #' + sample.code + ' (' + sample.coreType + ' Core)'"></span>
      </ng-container>
      <ng-container *ngIf="sample.type === 'FRAME'">
        <span [innerHtml]="'<strong>' + sample.description + '</strong> ' + sample.longDescription + ' (#' + sample.code + ')'"></span>
      </ng-container>
      <ng-container *ngIf="sample.type === 'BACKINGBOARD'">
        <span [innerHtml]="'<strong>' + sample.description.replace(' Backing Board', ':') + '</strong> ' + sample.longDescription"></span>
      </ng-container>
      <ng-container *ngIf="sample.type === 'GLAZING'">
        <span [innerHtml]="'<strong>' + sample.description.replace(' Plexiglass', ':') + '</strong> ' + sample.longDescription"></span>
      </ng-container>
      <ng-container *ngIf="sample.type === 'SHOWBAG'">
        <span [innerHtml]="'Show Bag <strong>' + sample.description + '</strong> ' + sample.longDescription + ' (#' + sample.code + ')'"></span>
      </ng-container>
      <ng-container *ngIf="sample.type === 'SAMPLE_PACK'">
        <span [innerHtml]="sample.description"></span>
      </ng-container>
    </div>
  </div>
  }
</div>


<div class="non-product-details" *ngIf="!cartItem.description && (!samples || samples.length === 0)">
  <mbnm-cart-item-part [cartItem]="cartItem" hide-gt-xs [hideBullet]="true"
                                   format="<{OuterSizeNumeric:size}>">
    <strong prefix *ngIf="['MAT','SHOW-KIT','VALUE-PACK'].indexOf(cartItem.primaryProduct) !== -1">Outer Size: </strong>
    <strong prefix *ngIf="['MAT','SHOW-KIT','VALUE-PACK'].indexOf(cartItem.primaryProduct) === -1">Size: </strong>
  </mbnm-cart-item-part>

  <mbnm-cart-item-part [cartItem]="cartItem" hide-gt-xs [hideBullet]="true"
                                   format="<{openings[0].size.width:size}> <x {openings[0].size.height:size}>">
    <strong prefix>Opening:</strong>
  </mbnm-cart-item-part>

  <ng-container *ngIf="!cartItem.description && (!samples || samples.length === 0)">
    <mbnm-cart-item-part [cartItem]="cartItem" [hideBullet]="true"
                                     format="<{openingDetails}>">
      <strong prefix>Opening Details:</strong>
    </mbnm-cart-item-part>

    <mbnm-cart-item-part [cartItem]="cartItem" hide-gt-xs [hideBullet]="true"
                         format="<{reveal:size}>">
      <strong prefix>Reveal:</strong>
    </mbnm-cart-item-part>

    <mbnm-cart-item-part [cartItem]="cartItem" [hideBullet]="true"
                         *ngIf="cartItem.margins?.bottom && cartItem.margins?.bottom !== cartItem.margins?.top"
                         format="<{margins.bottom:size}>">
      <strong prefix>Bottom Margin:</strong>
    </mbnm-cart-item-part>

    <mbnm-cart-item-part [cartItem]="cartItem" [hideBullet]="true"
                         format="<{vGrooveOffset:size}>">
      <strong prefix>V-Groove: Offset:</strong>
    </mbnm-cart-item-part>
  </ng-container>
</div>

<ng-container *ngIf="!cartItem.description && (!samples || samples.length === 0)">

  <mbnm-cart-item-part [cartItem]="cartItem"
                       format="<*{topMat.description}*> <#{topMat.code}> <({topMat.coreType} Core) Mat> <*({topMat.inactiveDescriptor})*> <&_*{bottomMat.description}*> <#{bottomMat.code}> <({bottomMat.coreType} Core) Accent Mat> <*({bottomMat.inactiveDescriptor})*>">
  </mbnm-cart-item-part>

  <mbnm-cart-item-part [cartItem]="cartItem" *ngIf="cartItem.primaryProduct !== 'MIRROR'"
                                   format="<{mirror.description}>">
  </mbnm-cart-item-part>

  <mbnm-cart-item-part [cartItem]="cartItem"
                                   format="<*{moulding.description}*:> <{moulding.longDescription}> <#{moulding.code}> </w {moulding.accessoryDescription}> <*({moulding.inactiveDescriptor})*>">
  </mbnm-cart-item-part>

  <mbnm-cart-item-part [cartItem]="cartItem" *ngIf="cartItem.primaryProduct !== 'SHOWBAG'"
                                   format="<{bag.description}> <*({bag.inactiveDescriptor})*>">
    <span>Show Bag</span>
  </mbnm-cart-item-part>

  <mbnm-cart-item-part [cartItem]="cartItem" *ngIf="cartItem.primaryProduct !== 'BACKINGBOARD'"
                                   format="<{backingBoard.description}> <*({backingBoard.inactiveDescriptor})*>">
  </mbnm-cart-item-part>

  <mbnm-cart-item-part [cartItem]="cartItem" *ngIf="cartItem.primaryProduct !== 'GLAZING'"
                                   format="<{glazing.description}> <*({glazing.inactiveDescriptor})*>">
  </mbnm-cart-item-part>

  <!--
  // NOTE: not using upsells in cart right now.. keep in case we want to use later
  <div class="upsell" *ngIf="upsell && cartItem.topMat && (!cartItem.bottomMat! && cartItem.openings.length > 0 || !cartItem.moulding)">
    <a mat-mini-fab class="close" color="secondary" (click)="upsell = false">
      <mat-icon svgIcon="x"></mat-icon>
    </a>
    <div *ngIf="cartItem.topMat && !cartItem.bottomMat && cartItem.openings.length > 0">
      <mat-icon color="secondary" (click)="upsellInfo('bottomMat')" svgIcon="information-outline"></mat-icon>
      <span>
        Consider Adding an Accent Mat
      </span>
      <button [routerLink]="'/edit/bottomMat/' + cartItem.id" mat-flat-button color="primary">Choose <mat-icon svgIcon="chevron-right"></mat-icon></button>
    </div>
    <hr *ngIf="cartItem.topMat && !cartItem.bottomMat! && !cartItem.moulding" />
    <div *ngIf="cartItem.topMat && !cartItem.moulding">
      <mat-icon color="secondary" (click)="upsellInfo('moulding')" svgIcon="information-outline"></mat-icon>
      <span>
        Consider Adding a Frame
      </span>
      <button [routerLink]="'/edit/moulding/' + cartItem.id" mat-flat-button color="primary">Choose <mat-icon svgIcon="chevron-right"></mat-icon></button>
    </div>
  </div>
  -->

</ng-container>

<mbnm-cart-item-part [cartItem]="cartItem"
                                 format="<{instructions}>">
  <strong content>Special Instructions:</strong>
</mbnm-cart-item-part>
