export class Shape {

  code: string;
  description: string;
  aspectRatio: number;
  aspectRatioTolerance: number;
  isCorner: boolean;
  allowForMultiLayer: boolean;
  options: any;

  constructor() {
  }

  static get DEFAULT(): Shape {
    return Shape.fromJson({ code: 'rect', description: 'Rectangle', allowForMultiLayer: true});
  }
  static fromJson(json): Shape {
    const retValue = new Shape();
    Object.assign(retValue, json);
    return retValue;
  }

  public toJson(): any {
    return {
      code: this.code,
      description: this.description,
      aspectRatio: this.aspectRatio,
      aspectRatioTolerance: this.aspectRatioTolerance,
      isCorner: this.isCorner,
      allowForMultiLayer: this.allowForMultiLayer
    };
  }

  toUrlString(): string {
    let url = this.code.replace(/ /g, '+');
    if (this.options) {
      url += '_';
      for (const key of Object.keys(this.options))
      {
        url += key + '=' + this.options[key];
      }
    }
    return url;
  }
}
