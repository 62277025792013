import {Component, Input, OnInit} from '@angular/core';
import {CartItem} from '../cart-item';
import {SessionService} from '../../../services/session.service';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {ProjectService} from '../../../services/project.service';

@Component({
  selector: 'mbnm-cart-item-description',
  templateUrl: './cart-item-description.component.html',
  styleUrls: ['./cart-item-description.component.scss']
})
export class CartItemDescriptionComponent implements OnInit {

  constructor(private router: Router, public dialog: MatDialog, private projectService: ProjectService) { }

  @Input()
  cartItem: CartItem;

  samples: Array<any>;
  upsell: boolean;
  hideUpsell = false;

  groupNames = {
    'MAT': {
      title: 'Matboards:',
      description: '2" x 4" Piece'
    },
    'FRAME': {
      title: 'Picture Frames:',
      description: '4" Stick'
    },
    'BACKINGBOARD': {
      title: 'Backing Boards:',
      description: '4" x 6" Piece'
    },
    'GLAZING': {
      title: 'Plexiglass:',
      description: '4" x 6" Piece'
    },
    'SHOWBAG': {
      title: 'Show Bags:'
    },
    'SAMPLE_PACK': {
      title: 'Sample Packs:'
    }
  }

  ngOnInit() {

    if (this.router.url.includes('checkout') || this.router.url.includes('order') || this.router.url.includes('confirmation')) {
      this.hideUpsell = true;
    }

    this.updateOpeningDetails(this.cartItem);
    this.updateOpeningDetails(this.cartItem.lastVersion);

    this.samples = this.cartItem.samples ? this.cartItem.samples.slice(0) : [];
    SessionService.abTestsChanged.subscribe( abTests => {
      this.upsell = false;
    });

    const oldSamples = this.cartItem.lastVersion && this.cartItem.lastVersion.samples ? this.cartItem.lastVersion.samples.slice(0) : [];
    oldSamples.forEach(sample => {
      sample.deleted = this.samples.filter( s => {
        return s.code === sample.code;
      }).length === 0;
    });

    this.samples.forEach(sample => {
      sample.new = oldSamples.length > 0 && oldSamples.filter( s => {
        return s.code === sample.code;
      }).length === 0;
    });

    this.samples = this.samples.concat(oldSamples.filter( sample => sample.deleted));

    if (this.samples.length > 0) {
      this.samples.sort( (a:any,b:any) => {
        let aType = a.type.toUpperCase();
        let bType = b.type.toUpperCase();
        return (aType < bType) ? -1 : (aType > bType) ? 1 : 0;
      });

      let groupName = null;
      this.samples.forEach( sample => {
        if (this.groupNames[sample.type] != null && this.groupNames[sample.type].title !== groupName) {
          sample.groupName = this.groupNames[sample.type].title;
          sample.groupNameDescription = this.groupNames[sample.type].description;
          groupName = sample.groupName;
        }
      });
    }

    // create useful descriptions when they are missing due to very old order with bad part code
    ['backingBoard:Backing Board', 'glazing:Plexiglass', 'topMat:Matboard', 'bottomMat:Matboard', 'moulding:Moulding', 'bag:Show Bag'].forEach(part => {
      const prop = part.split(':')[0];
      const partType = part.split(':')[1];
      if (this.cartItem[prop]) {
        if (!this.cartItem[prop].description) {
          this.cartItem[prop].description = partType + ' #' + this.cartItem[prop].code;
          this.cartItem[prop].longDescription = partType + ' #' + this.cartItem[prop].code;
        }
      }
    })
  }

  private updateOpeningDetails(cartItem): void {
    if (cartItem && cartItem.openings) {
      if (cartItem.openings.length === 1 && cartItem.openings[0].shape[0].code === 'rect') {
        cartItem.openingDetails = null;
      } else {
        const openingGroups: object = {};
        cartItem.openings.forEach( opening => {
          if (opening.shape) {
            const code = opening.shape[0].code;
            if (!openingGroups[code]) {
              openingGroups[code] = {cnt: 0, shape: opening.shape[0]};
            }
            openingGroups[code].cnt ++;
          }
        });
        let openingDetails = '';
        for (const prop in openingGroups) {
          const openingGroup = openingGroups[prop];
          if (openingGroup.cnt > 1) {
            openingDetails += `(${openingGroup.cnt}) ${openingGroup.shape.description} Openings, `;
          } else {
            openingDetails += `${openingGroup.shape.description} Opening, `;
          }
        };
        cartItem.openingDetails = openingDetails.replace(/, $/, '');
      }
    }
  }

  public splitDescription(description: string): string[] {
    return description.split('\r\n');
  }

  /*
  NOT BEING USED CURRENTLY
  public upsellInfo(property: string): void {
    const dialogRef = this.dialog.open(YesNoDialogComponent, {});
    const instance = dialogRef.componentInstance;
    instance.data = {
      closeMethod: 'button',
      noButtonText: 'No Thanks'
    }

    const proj = new Project(this.cartItem);
    if (property === 'bottomMat') {
      proj.bottomMat = proj.topMat;
      proj.moulding = null; // mouldings don't work with section code
      const margins = proj.margins;
      const section = proj.openings.length === 0 ? null : (margins.left - 1) + 'x' + (margins.top - 1) + 'x' + (proj.reveal + 2) + 'x' + (proj.reveal +  2);
      const imageUrl = proj.url(250, null, section);

      instance.data.title = 'Upgrade to a double mat';
      instance.data.message = '<p class="center">We recommend that you <strong>add an accent mat</strong> to your project.</p>' +
        '<p class="image shadow center"><img src="' + imageUrl + '" /></p>' +
        '<p class="center">Adding a second layer of matting gives you more depth and color.</p>';
      instance.data.yesButtonText = 'Choose Bottom Mat';
    } else {
      proj.moulding = {code: 'FR03'};
      proj.glazing = {code: 'PLEXIGLASS'};
      proj.backingBoard = {code: 'FOAM'};
      this.projectService
        .getPrice(proj)
        .subscribe((result) => {
          const delta = result.price - this.cartItem.price;
          const imageUrl = proj.url(250);
          const currencyPipe = new CurrencyPipe('en');
          instance.data.title =  'Add a frame';
          instance.data.message = '<p class="center"><strong>Order a picture frame</strong> that will fit your mat perfectly.</p>' +
            '<p class="image shadow center"><img src="' + imageUrl + '" />' +
            '<span class="overlay upsell">' + currencyPipe.transform(delta) + '</span></p>' +
            '<p class="center">Choose from our many frame styles!</p>';
          instance.data.yesButtonText = 'Choose Frame';
        });
    }

    dialogRef.afterClosed().subscribe( (result) => {
      if (result) {
        this.router.navigateByUrl('/edit/' + property + '/' + this.cartItem.id);
      }
    });
  }
  */


}
