import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable()
export class ContactUsService {
  contactBaseUrl = environment.baseUrls().api + 'contact/';

  constructor(private httpClient: HttpClient) {

  }

  sendMessage(message: any): Observable<any> {
    const formData: FormData = new FormData();

    if (message.attachments) {
      for (let i = 0; i < message.attachments.length; i++) {
        formData.append('fileUpload', message.attachments[i], message.attachments[i].name);
      }
    }

    const msg = Object.assign({}, message);
    delete msg.attachments;
    formData.append('message', JSON.stringify(msg));

    const req = new HttpRequest('POST', this.contactBaseUrl + 'message', formData,{
      reportProgress: true
    });

    return this.httpClient
      .request(req)
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return {
                progress: Math.round(100 * event.loaded / event.total)
              };
            case HttpEventType.DownloadProgress: {
              return null;
            }
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }
}


