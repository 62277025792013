import {Component, HostBinding, HostListener, Inject, Input, OnInit, PLATFORM_ID} from '@angular/core';
import {DatePipe, isPlatformBrowser} from '@angular/common';
import {LargeImageDialogComponent} from "../../large-image-dialog/large-image-dialog.component";
import {MatDialog} from "@angular/material/dialog";

@Component({
  selector: 'mbnm-review',
  templateUrl: './review.component.html',
  styleUrls: ['./review.component.scss'],
  providers: [DatePipe]
})
export class ReviewComponent implements OnInit {

  @HostBinding('class.aggregate')
  get aggregate() {
    return this.review ? this.review.isAggregate : false;
  }

  @Input()
  review: any;

  @Input()
  customerDisplayStyle = 'avatar';

  constructor(@Inject(PLATFORM_ID) private platformId: Object, public dialog: MatDialog) {
  }

  get starsAsArray(): Array<number> {
    if (this.review) {
      return Array.apply(null, Array(this.review.rating)).map(function (x, i) { return i + 1; });
    }
    return [];
  }

  showImage(title, image) {
    this.dialog
      .open(LargeImageDialogComponent, {
        data: {
          title: title,
          largeImage: image
        }
      })
  }
  ngOnInit() {
  }

  public platformIsBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }
}
