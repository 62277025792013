import { Injectable } from '@angular/core';
import {map, mergeMap} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {first, firstValueFrom, Observable} from 'rxjs';
import {User} from '../models/user';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SessionService} from './session.service';
import {CookieService} from '../../cookie.service';

@Injectable()
export class UserService {

  constructor(private httpClient: HttpClient, private sessionService: SessionService, protected cookieService: CookieService) {
  }

  register(newUserObj) {
    return firstValueFrom(
      this.httpClient
        .post(environment.baseUrls().api + 'user', newUserObj)
        .pipe(
          map((res:any) => {
            const date = new Date();
            date.setDate(date.getDate() + 60);
            this.cookieService.put('CurrentToken', res.currentToken, {
              expires: date,
              path: '/'
            });
            const user: User = new User(res);
            this.sessionService.updateCurrentUser(user);
            return user;
          })
        )
    );
  }

  update(userObj) {
    return firstValueFrom(
      this.httpClient
        .patch(environment.baseUrls().api + 'user', userObj)
        .pipe(
          map((res: any) => {
            const user: User = new User(res);
            this.sessionService.updateCurrentUser(user);
            return user;
          })
        )
    );
  }

  signUpForMailingList(userObj) {
    return firstValueFrom(
      this.httpClient
        .post(environment.baseUrls().api + 'user', userObj)
        .pipe(
          map((res: any) => {
            const user: User = new User(res);
            this.sessionService.updateCurrentUser(user);
            return user;
          })
        )
    );
  }

  resetPassword(newPassword: string, forgotPasswordSecret: string) {
    const formData = new FormData();
    formData.append('newPassword', newPassword);
    formData.append('forgotPasswordSecret', forgotPasswordSecret);

    return firstValueFrom(
      this.httpClient
        .post(environment.baseUrls().api + 'user/password', formData)
        .pipe(
          map((res: any) => {
            const u: User = new User(res);
            this.sessionService.updateCurrentUser(u);
            return u;
          })
        )
    );
  }

  updatePassword(email: string, oldPassword: string, newPassword: string) {
    const formData = new FormData();
    formData.append('email', email);
    formData.append('oldPassword', oldPassword);
    formData.append('newPassword', newPassword);

    return firstValueFrom(
      this.httpClient
        .patch(environment.baseUrls().api + 'user/password', formData)
        .pipe(
          map((res: any) => {
            const u: User = new User(res);
            this.sessionService.updateCurrentUser(u);
            return u;
          })
        )
    );
  }

  forgotPassword(email: string) {
    const headers = new HttpHeaders ({
      'Content-Type': 'application/json; charset=utf-8',
    });
    return firstValueFrom(
      this.httpClient
        .post(environment.baseUrls().api + 'user/forgotpassword', JSON.stringify(email), { headers} )
    );
  }

  getCoupons() {
    return firstValueFrom(
      this.httpClient.get(environment.baseUrls().api + 'user/coupons/')
    );
  }
}
