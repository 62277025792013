import { Injectable } from '@angular/core';

@Injectable()
export class SizeService {

  evalFraction(size): number {
    if (!size && size !== 0) size = '';
    if (isNaN(size)) {
      size = size.trim();
      size = size.replace(/¹¹⁄₁₆/g, '+11/16');
      size = size.replace(/¹³⁄₁₆/g, '+13/16');
      size = size.replace(/¹⁵⁄₁₆/g, '+15/16');
      size = size.replace(/¹⁄₁₆/g, '+1/16');
      size = size.replace(/³⁄₁₆/g, '+3/16');
      size = size.replace(/⁵⁄₁₆/g, '+5/16');
      size = size.replace(/⅛/g, '+1/8');
      size = size.replace(/¼/g, '+1/4');
      size = size.replace(/⅜/g, '+3/8');
      size = size.replace(/½/g, '+1/2');
      size = size.replace(/⅝/g, '+5/8');
      size = size.replace(/¾/g, '+3/4');
      size = size.replace(/⁷⁄₁₆/g, '+7/16');
      size = size.replace(/⁹⁄₁₆/g, '+9/16');
      size = size.replace(/⅞/g, '+7/8');
      size = size.replace(/\"/g, '');
      size = size.replace(/ \+/g, '+');
      size = size.replace(/ /g, '+');
      return parseFloat((0, eval)(size));
    } else {
      return parseFloat(size);
    }
  }

  fracToString(frac) {
    if (frac === 0) return '';
    if (frac <= 1 / 16) return '¹⁄₁₆';
    if (frac <= 1 / 8) return '⅛';
    if (frac <= 3 / 16) return '³⁄₁₆';
    if (frac <= 1 / 4) return '¼';
    if (frac <= 5 / 16) return '⁵⁄₁₆';
    if (frac <= 3 / 8) return '⅜';
    if (frac <= 7 / 16) return '⁷⁄₁₆';
    if (frac <= 1 / 2) return '½';
    if (frac <= 9 / 16) return '⁹⁄₁₆';
    if (frac <= 5 / 8) return '⅝';
    if (frac <= 11 / 16) return '¹¹⁄₁₆';
    if (frac <= 3 / 4) return '¾';
    if (frac <= 13 / 16) return '¹³⁄₁₆';
    if (frac <= 7 / 8) return '⅞';
    if (frac <= 15 / 16) return '¹⁵⁄₁₆';
    return null;
  }

  fracToStringMultiChar(frac) {
    if (frac <= 1 / 16) return '1/16';
    if (frac <= 2 / 16) return '1/8';
    if (frac <= 3 / 16) return '3/16';
    if (frac <= 4 / 16) return '1/4';
    if (frac <= 5 / 16) return '5/16';
    if (frac <= 6 / 16) return '3/8';
    if (frac <= 7 / 16) return '7/16';
    if (frac <= 8 / 16) return '1/2';
    if (frac <= 9 / 16) return '9/16';
    if (frac <= 10 / 16) return '5/8';
    if (frac <= 11 / 16) return '11/16';
    if (frac <= 12 / 16) return '3/4';
    if (frac <= 13 / 16) return '13/16';
    if (frac <= 14 / 16) return '7/8';
    if (frac <= 15 / 16) return '15/16';
    return null;
  }

  fractionAsString(num) {
    if (num % 1 > 15 / 16) return Math.floor(num) + 1;

    var fracPart = this.fracToString(num % 1);
    if (fracPart == null) return num;

    var wholePart = Math.floor(num);
    if (wholePart == num) return wholePart;
    else if (wholePart != 0) return wholePart + ' ' + fracPart;
    else return fracPart;
  }

  fractionAsStringMultiChar(num) {
    if (num % 1 > 15 / 16) return Math.floor(num) + 1;

    var fracPart = this.fracToStringMultiChar(num % 1);
    if (fracPart == null) return num;

    var wholePart = Math.floor(num);
    if (wholePart === num) return wholePart;
    else if (wholePart !== 0) return wholePart + ' ' + fracPart;
    else return fracPart;
  }

  sizeSpecsForProject(project) {

    if (project.outerSize) {
      let outerSizeParts = project.outerSize.split(' x ');

      let openingSizeParts = !project.openingSize || project.openingSize == 'No Opening' ? '0 x 0'.split(' x ') : project.openingSize.split(' x ');

      let reveal = project.reveal && project.bottomMat ? project.reveal : 0,
        l = (this.evalFraction(outerSizeParts[0]) - this.evalFraction(openingSizeParts[0])) / 2,
        r = l,
        t = (this.evalFraction(outerSizeParts[1]) - this.evalFraction(openingSizeParts[1])) / 2,
        b = t,
        openingSize = this.evalFraction(openingSizeParts[0]) + 'x' + this.evalFraction(openingSizeParts[1]),
        outerSize = this.evalFraction(outerSizeParts[0]) + 'x' + this.evalFraction(outerSizeParts[1]),
        topCenterOffset = Math.max((this.evalFraction(outerSizeParts[1]) - this.evalFraction(openingSizeParts[1])) / 2 - (this.evalFraction(outerSizeParts[0]) - this.evalFraction(openingSizeParts[0])) / 2, 0),
        offset = project.offset ? (0, eval)(project.offset) : 0.0;

      b += offset;
      t -= offset;

      // if no opening
      if (openingSizeParts[0] == 0 && openingSizeParts[1] == 0) t = l = b = r = 0;

      return { topMargin: t, leftMargin: l, bottomMargin: b, rightMargin: r, offset: offset, reveal: reveal, openingSize: openingSize, outerSize: outerSize, topCenterOffset: topCenterOffset };
    }
    else if (project.bottomMat) {
      return { topMargin: 1.5, leftMargin: 1.5, bottomMargin: 1.5, rightMargin: 1.5, offset: 0.0, reveal: 0.25, openingSize: '5x7', outerSize: '8x10', topCenterOffset: 0 };
    } else {
      return { topMargin: 1.5, leftMargin: 1.5, bottomMargin: 1.5, rightMargin: 1.5, offset: 0.0, reveal: 0, openingSize: '5x7', outerSize: '8x10', topCenterOffset: 0 };
    }
  }

  constructor() { }

}
