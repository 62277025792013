import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule } from '@angular/common';
import {InchesPipe} from './pipes/inches.pipe';
import {SizePipe} from './pipes/size.pipe';
import {KeysPipe} from './pipes/keys.pipe';
import {SizeService} from './services/size.service';
import {ProductsService} from './services/products.service';
import {Currency2Pipe } from './pipes/currency2.pipe';
import {StringFormatPipe } from './pipes/string-format.pipe';
import {ProjectService} from './services/project.service';
import {CartService} from './services/cart.service';
import {UserService} from './services/user.service';
import {SessionService} from './services/session.service';
import {ReviewsService} from './services/reviews.service';
import {OrderService } from './services/order.service';
import {ReviewsComponent} from './components/reviews/reviews.component';
import {MatIconModule} from '@angular/material/icon';
import {MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import {SanitizerPipe } from './pipes/sanitizer.pipe';
import {UploadService} from './services/upload.service';
import {RouterModule} from '@angular/router';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {ReviewComponent} from './components/reviews/review/review.component';
import {CreditCardMaskPipe } from './pipes/credit-card-mask.pipe';
import {CartItemComponent } from './components/cart-item/cart-item.component';
import {CartItemPartComponent } from './components/cart-item/cart-item-part/cart-item-part.component';
import {CartItemDescriptionComponent } from './components/cart-item/cart-item-description/cart-item-description.component';
import {FormsModule} from '@angular/forms';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NotificationService} from './services/notification.service';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {SeoService} from './services/seo.service';
import {ContactUsService} from './services/contact-us.service';
import {QtyDirective } from './directives/qty.directive';
import {MouldingMerchComponent } from './components/moulding-merch/moulding-merch.component';
import {UploaderComponent } from './components/uploader/uploader.component';
import {PromotionComponent } from './components/promotion/promotion.component';
import {CarouselComponent} from './components/carousel/carousel.component';
import {AddClassDirective} from './directives/add-class.directive';
import {BasicMerchComponent} from './components/basic-merch/basic-merch.component';
import {NavigationTilesComponent} from './components/navigation-tiles/navigation-tiles.component';
import {NavigationTileComponent} from './components/navigation-tile/navigation-tile.component';
import {CmsService} from './services/cms.service';
import {WINDOW_PROVIDERS} from './services/window.service';
import {StateManagerService} from './services/state-manager.service';
import { SortByPipe } from './pipes/sort-by.pipe';
import { CreditCardFormatDirective } from './directives/credit-card/credit-card-format.directive';
import { CvcFormatDirective } from './directives/credit-card/cvc-format.directive';
import { ExpiryFormatDirective } from './directives/credit-card/expiry-format.directive';
import {CouponService} from './services/coupon.service';
import {ShopComponent} from "./components/shop/shop.component";
import { PasswordRevealerComponent } from './components/password-revealer/password-revealer.component';
import {MatInputModule} from "@angular/material/input";
import {LargeImageDialogComponent} from "./components/large-image-dialog/large-image-dialog.component";
import {GooglePlacesService} from "./services/googlePlaces.service";

@NgModule({
  imports: [
    CommonModule, MatIconModule, MatProgressSpinnerModule, RouterModule, MatButtonModule, MatTabsModule,
    FormsModule, MatCheckboxModule, MatSnackBarModule, MatInputModule
  ],
  declarations: [
    InchesPipe, SizePipe, KeysPipe, Currency2Pipe, StringFormatPipe, SanitizerPipe, CreditCardMaskPipe,
    ReviewsComponent, ReviewComponent, CartItemComponent, CartItemPartComponent, CartItemDescriptionComponent,
    QtyDirective, MouldingMerchComponent, UploaderComponent, PromotionComponent, LargeImageDialogComponent,
    CarouselComponent, AddClassDirective, BasicMerchComponent, NavigationTilesComponent, NavigationTileComponent, SortByPipe,
    CreditCardFormatDirective, CvcFormatDirective, ExpiryFormatDirective, ShopComponent, PasswordRevealerComponent
  ],
  exports: [
      InchesPipe, SizePipe, KeysPipe, Currency2Pipe, StringFormatPipe, SanitizerPipe, CreditCardMaskPipe, SortByPipe,
      ReviewsComponent, CartItemComponent, QtyDirective, MouldingMerchComponent, UploaderComponent, LargeImageDialogComponent,
      PromotionComponent, CarouselComponent, AddClassDirective, BasicMerchComponent, NavigationTilesComponent, NavigationTileComponent,
      CreditCardFormatDirective, CvcFormatDirective, ExpiryFormatDirective, ShopComponent, PasswordRevealerComponent
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        SizeService, ProductsService, ProjectService, CartService, SessionService, UserService,
        ReviewsService, OrderService, UploadService, NotificationService, SeoService, ContactUsService,
        CouponService, CmsService, StateManagerService, GooglePlacesService,
        WINDOW_PROVIDERS
      ]
    };
  }
}
