export class ApplicationError extends Error {

  data:any;
  constructor(message?: string, data: any = null) {
    super(message);
    this.data = data;
    this.name = ApplicationError.name;
    Object.setPrototypeOf(this, ApplicationError.prototype);
  }
}
