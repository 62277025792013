import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {CartItem} from './cart-item';
import {ProjectService} from '../../services/project.service';
import {CartService} from '../../services/cart.service';
import {Size} from '../../models/size';
import {BreakpointObserver} from "@angular/cdk/layout";

@Component({
  selector: 'mbnm-cart-item',
  templateUrl: './cart-item.component.html',
  styleUrls: ['./cart-item.component.scss'],
  host: {
    '[class.updating]': 'cartItem.updating',
    '[class.readonly]': 'readonly',
    '[class.deleted]': 'deleted'
  }
})
export class CartItemComponent implements OnChanges {

  @Input()
  cartItem: CartItem;

  @Input()
  readonly: boolean;

  @Input()
  deleted: boolean;

  @Input()
  enableReBuy = false;

  @Input()
  hideDivider = false;

  @Output()
  itemRemoved: EventEmitter<CartItem> = new EventEmitter<CartItem>();

  @Output()
  qtyUpdate: EventEmitter<any> = new EventEmitter();

  @Output()
  reBuyRequested: EventEmitter<any> = new EventEmitter();

  qty: number;

  qtyUpdateTimeout;

  constructor(private projectService: ProjectService, private cartService: CartService, public media: BreakpointObserver) { }

  ngOnChanges() {
    this.setHeading();
    this.cartService.setCartItemExtras(this.cartItem);
    this.qty = this.cartItem.qty;
  }

  public removeItem(): void {
    this.cartItem.updating = true;
    this.itemRemoved.emit(this.cartItem);
  }

  public updateQty(): void {
    clearTimeout(this.qtyUpdateTimeout);
    if (!isNaN(this.qty) && this.qty !== null) {
      this.qtyUpdateTimeout = setTimeout(() => {
        this.cartItem.updating = true;
        this.qtyUpdate.emit({ id: this.cartItem.id, qty: this.qty });
      }, 500);
    }
  }

  private setHeading(): void {
    if (this.cartItem) {
      const item = this.cartItem;
      if (item.title) {
        item.heading = item.title;
      } else if (item.primaryProduct === 'MAT' && item.topMat) {
        item.heading = item.topMat.categoryName;
      } else if (item.primaryProduct === 'MIRROR' && item.mirror.description) {
        item.heading = item.mirror.description;
      } else if ((item.primaryProduct === 'FRAME' || item.primaryProduct === 'MFRAME') && item.moulding.longDescription) {
        item.heading = item.moulding.description + ' Frame';
      } else if (item.primaryProduct === 'BACKINGBOARD' && item.backingBoard.description) {
        item.heading = item.backingBoard.description;
      } else if (item.primaryProduct === 'GLAZING' && item.glazing.description) {
        item.heading = item.glazing.description;
      } else if (item.primaryProduct === 'SHOWBAG') {
        item.heading = 'Show Bags';
      } else if (item.primaryProduct === 'SHOW-KIT') {
        item.heading = this.getSampleProductDesc(item) + ' Show Kit';
      } else if (item.primaryProduct === 'VALUE-PACK') {
        item.heading = this.getSampleProductDesc(item) + ' Value Pack';
      } else if (item.primaryProduct === 'SAMPLE') {
        if (item.samples && item.samples.length > 0) {
          item.heading = 'Product Samples';
        } else {
          item.heading = this.getSampleProductDesc(item) + ' Sample';
        }
      } else if (item.description) {
        item.heading = item.description;
      }
    }
  }

  private getSampleProductDesc(item: CartItem): string {
    let desc = '';
    if (item.topMat) {
      desc = item.topMat.categoryName;
    } else if (item.mirror) {
      desc = item.mirror.description;
    } else if (item.moulding) {
      desc = item.moulding.description;
    } else if (item.backingBoard) {
      desc = item.backingBoard.description;
    } else if (item.glazing) {
      desc = item.glazing.description;
    } else if (item.bag) {
      desc = 'Show Bags';
    }
    return desc;
  }
  requestReBuy($event) {
    this.reBuyRequested.emit({
      item: this.cartItem
    });
  }
}
