import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable()
export class ReviewsService {
  reviewBaseUrl = environment.baseUrls().api + 'reviews/';
  constructor(private httpClient: HttpClient) { }

  getAggregateInfo() {
    return firstValueFrom(
      this.httpClient
        .get<any>(this.reviewBaseUrl + 'aggregate')
    );
  }

  getCustomerReviews(limit: number, starRating: string, filterBy: string, tag: string = null) {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    let url = this.reviewBaseUrl + '?perPage=' + limit;
    if (tag) {
      url += ('&tag=' + tag);
    }

    if (starRating) {
      url += ('&starRating=' + starRating);
    }

    if (filterBy) {
      url += ('&filterBy=' + filterBy);
    }

    return firstValueFrom(
      this.httpClient.get<any>(url)
    );
  }
}
