import {Component, Input, OnInit} from '@angular/core';
import {Project} from '../../models/project';

@Component({
  selector: 'mbnm-basic-merch',
  templateUrl: './basic-merch.component.html',
  styleUrls: ['./basic-merch.component.scss']
})
export class BasicMerchComponent implements OnInit {

  constructor() { }

  @Input()
  project: Project;

  @Input()
  projectPropertyName: string;

  @Input()
  imageUrl: string;

  @Input()
  title: string;

  public projectProperty: any;

  ngOnInit() {
    this.projectProperty = this.projectPropertyName ? this.project[this.projectPropertyName] : null;
    this.title = this.projectProperty ? this.projectProperty.description : this.title;
  }

}
