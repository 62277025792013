import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'mbnm-moulding-merch',
  templateUrl: './moulding-merch.component.html',
  styleUrls: ['./moulding-merch.component.scss']
})
export class MouldingMerchComponent implements OnInit {

  constructor() { }

  @Input()
  moulding: any;

  @Input()
  hangerType: string;

  public activeView = 'profile';

  setActive(view) {
    this.activeView = view;
  }

  ngOnInit() {
  }

}
