import {
  AfterContentChecked,
  AfterContentInit,
  Component, ContentChild,
  ElementRef,
  Input,
  OnInit
} from '@angular/core';
import { MatTabGroup, MatTabHeaderPosition } from '@angular/material/tabs';

@Component({
  selector: 'mbnm-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
  host: {
    '[class.nav-over-slides]': 'navPosition === "over-slides"',
    '[class.nav-hidden]': 'navPosition === "hidden"'
  }
})
export class CarouselComponent implements OnInit, AfterContentInit, AfterContentChecked {

  constructor(public elementRef: ElementRef) {
  }

  public oneTabOnly = false;
  @ContentChild(MatTabGroup) tabGroup: MatTabGroup;
  @Input() navPosition: MatTabHeaderPosition = 'above';

  ngOnInit() {
  }

  ngAfterContentInit() {
    this.tabGroup.headerPosition = this.navPosition;
    this.tabGroup.dynamicHeight = true;
  }

  ngAfterContentChecked() {
    this.oneTabOnly = this.tabGroup._tabs.length === 1;
  }
}

