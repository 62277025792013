<h2 mat-dialog-title>Upload Disclaimer  <button mat-icon-button mat-dialog-close *ngIf="data.closeMethod == 'x'" tabindex="-1">
  <mat-icon svgIcon="x"></mat-icon>
</button></h2>
<mat-dialog-content>
  <p>
    The image(s) you upload here are meant to help visualize a preview of
    the final product & will only be used for that purpose.
  </p>
  <p>
    <strong>No print will be provided with your order.</strong>
  </p>
</mat-dialog-content>
<mat-dialog-actions class="fx-layout-row fx-align--space-between-x">
  <button mat-flat-button color="primary">
    <mbnm-uploader [disclaimerAccepted]="true"
                   (uploadComplete)="onUploadComplete($event)"
                   (uploadFailed)="onUploadFailed($event)"
                   [acceptedFileTypes]="acceptedFileTypes"
                   [allowMultiple]="allowMultiple"
                   [uploadPath]="uploadPath">
      Accept & Upload
    </mbnm-uploader>
  </button>
  <button color="secondary" mat-flat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
