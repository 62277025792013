import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpEventType, HttpRequest} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class UploadService {
  private baseUrl = environment.baseUrls().api;
  constructor(private httpClient: HttpClient) { }

  upload(filesToUpload: FileList, path = ''): Observable<any> {
    const formData: FormData = new FormData();
    for (let i = 0; i < filesToUpload.length; i++) {
      formData.append('files', filesToUpload.item(i), filesToUpload[i].name);
    }

    const req = new HttpRequest('POST', this.baseUrl + 'upload' + path, formData,{
      reportProgress: true
    });
    return this.httpClient
      .request(req)
      .pipe(
        map((event: any) => {
          switch (event.type) {
            case HttpEventType.UploadProgress:
              return {
                progress: Math.round(100 * event.loaded / event.total)
              };
            case HttpEventType.DownloadProgress: {
              return null;
            }
            case HttpEventType.Response:
              return event.body;
          }
        })
      );
  }
}
