<hr *ngIf="!hideDivider" />

<div class="cart-item-header">
  <span class="heading" [ngClass]="{new: cartItem.lastVersion === null && !deleted}">
    <span *ngIf="cartItem.packSize > 1">
      {{ cartItem.packSize }} Pack of
    </span>
    {{cartItem.heading || 'Matboard'}}
  </span>
  <div class="top-right" *ngIf="enableReBuy && readonly">
    <span style="float:left; margin: 4px 10px 0 0">
      <mat-icon *ngIf="cartItem.rebought" svgIcon="check"></mat-icon>
    </span>
    <button mat-flat-button color="primary" (click)="requestReBuy($event)">
      Add to Cart
    </button>
  </div>
  <div class="action-buttons" *ngIf="!enableReBuy">
    <button mat-icon-button class="icon edit" color="secondary" *ngIf="cartItem.editable && !readonly" [routerLink]="'/edit/' + cartItem.id">
      <mat-icon svgIcon="edit-cart"></mat-icon>
    </button>
    <button mat-icon-button class="icon del"  color="secondary" (click)="removeItem()" *ngIf="!readonly">
      <mat-icon svgIcon="trash"></mat-icon>
    </button>
  </div>
</div>

<div class="inactive" *ngIf="cartItem.hasInactiveParts">
  <mat-icon svgIcon="alert" /> This item contains <strong>inactive parts</strong>.  Please edit or remove to checkout.
</div>

<div class="inactive" *ngIf="cartItem.hasInactivePreConfiguredProduct">
  <mat-icon svgIcon="alert" /> This item is <strong>not active</strong>.  Please remove to checkout.
</div>

<div class="fx-layout-row fx-gap--16px">

  <div class="thumb" *ngIf="cartItem.primaryProduct !== 'SAMPLE'">
    <img [ngClass]="{shadow: cartItem.thumbNeedsShadow}" [src]="cartItem.thumbUrl" alt="Cart Item Thumb"/>
  </div>

  <div class="columns
              fx-layout-row fx-layout-wrap--gt-xs
              fx-align--start-x fx-align--x-start
              fx-flex--100">

    <mbnm-cart-item-part style="width: 72px" [columnTitle]="cartItem.primaryProduct === 'SAMPLE' ? 'Count' : 'Qty'"
                                     [cartItem]="cartItem" class="qty"
                                     format="<{qty}>">
      <span after>
        <input class="data"
               type="number" mbnmQty
               [disabled]="readonly || cartItem.primaryProduct == 'SAMPLE' || cartItem.price == 0"
               name="qty"
               min="0"
               (keyup)="updateQty()"
               [ngStyle]=""
               [(ngModel)]="qty" />
      </span>
    </mbnm-cart-item-part>

    <ng-container *ngIf="cartItem.primaryProduct !== 'SAMPLE'">
      <mbnm-cart-item-part [cartItem]="cartItem" class="hide-xs"
                                       [columnTitle]="cartItem.primaryProduct === 'MAT' ? 'Size' : 'Size'"
                                       format="<{OuterSizeNumeric:size}>">
      </mbnm-cart-item-part>

      <mbnm-cart-item-part [cartItem]="cartItem" class="hide-xs"
                           columnTitle="Opening" *ngIf="cartItem.openings.length === 1"
                           format="<{openings[0].size.width:size}><x {openings[0].size.height:size}>">
      </mbnm-cart-item-part>
      <mbnm-cart-item-part [cartItem]="cartItem" class="hide-xs"
                           columnTitle="Openings" *ngIf="cartItem.openings.length > 1"
                           format="<Custom ({openings.length})>">
      </mbnm-cart-item-part>


      <mbnm-cart-item-part [cartItem]="cartItem" class="hide-xs"
                                       columnTitle="Reveal"
                                       format="<{reveal:size}>">
      </mbnm-cart-item-part>

    </ng-container>

    <div class="fill"></div>

    <mbnm-cart-item-part [cartItem]="cartItem"
                         *ngIf="cartItem.samples?.length === 0"
                         columnTitle="Each"
                         format="<{price:currency}>">
    </mbnm-cart-item-part>


    <mbnm-cart-item-part [cartItem]="cartItem"
                                     columnTitle="Total"
                                     format="<{totalPrice:currency}>">
    </mbnm-cart-item-part>

    <mbnm-cart-item-description [cartItem]="cartItem" class="fx-flex--100 hide-xs">
    </mbnm-cart-item-description>
  </div>
</div>
<mbnm-cart-item-description class="hide-gt-xs" [cartItem]="cartItem">

</mbnm-cart-item-description>
