import {Component, ContentChild, DoCheck} from '@angular/core';
import {MatInput} from "@angular/material/input";

@Component({
  selector: 'mbnm-password-revealer',
  templateUrl: './password-revealer.component.html',
  styleUrls: ['./password-revealer.component.scss']
})
export class PasswordRevealerComponent implements DoCheck {

  @ContentChild(MatInput) input: MatInput;
  constructor() {
  }

  public showPassword = false;
  public showEye = false;

  public toggleShowPassword($event) {
    $event.preventDefault();
    this.showPassword = !this.showPassword;
    this.input.type = this.showPassword ? 'text' : 'password';
  }

  ngDoCheck(): void {
    if (this.input) {
      this.showEye = this.input.value ? true : false;
    }
  }
}
