import { Inject, Injectable, PLATFORM_ID, makeStateKey, TransferState } from '@angular/core';

import {isPlatformServer} from '@angular/common';

@Injectable()
export class StateManagerService {
  constructor(@Inject(PLATFORM_ID) private platformId,
              private transferState: TransferState) {

  }

  private prefix: string = null;
  private noVal = 'NO-VALUE';

  public setPrefix(prefix: string): void {
    this.prefix = prefix + '-';
  }

  public getStateKeyValue(keyStr: string): any {
    if (this.prefix === null) {
      throw new Error('Prefix not set!');
    }

    const key = makeStateKey<any>(this.prefix + keyStr);
    if (this.transferState.hasKey(key)) {
      const val = this.transferState.get<any>(key, null);
      this.transferState.remove(key);
      return {
        key: key,
        val: val
      };
    } else {
      return {
        key: key,
        val: this.noVal
      };
    }
  }

  public setStateValue(key, val: any, force = false): void {
    if (isPlatformServer(this.platformId) || force) {
      this.transferState.set(key, val);
    }
  }

  public hasValue(val: any): boolean {
    return val !== this.noVal;
  }
}
