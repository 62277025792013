import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, from, of, ReplaySubject, Subject, firstValueFrom} from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { CartItem } from '../components/cart-item/cart-item';
import { Project } from '../models/project';
import {environment} from '../../../environments/environment';
import {WINDOW} from './window.service';
import {CreditCardMaskPipe} from '../pipes/credit-card-mask.pipe';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';

@Injectable()
export class GooglePlacesService {
  private static _googlePlacesSessionToken: any;
  private static _initialized = false;

  constructor(private httpClient: HttpClient,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  public async initialize() {
    if (!GooglePlacesService._initialized) {
      // load the Google Maps API (once only)
      if (!this.document.head.querySelector('script[id=places]')) {
        // @ts-ignore
        (g=>{var h,a,k,p="The Google Maps JavaScript API",c="google",l="importLibrary",q="__ib__",m=document,b=window;b=b[c]||(b[c]={});var d=b.maps||(b.maps={}),r=new Set,e=new URLSearchParams,u=()=>h||(h=new Promise(async(f,n)=>{await (a=m.createElement("script"));e.set("libraries",[...r]+"");for(k in g)e.set(k.replace(/[A-Z]/g,t=>"_"+t[0].toLowerCase()),g[k]);e.set("callback",c+".maps."+q);a.id='places';a.src=`https://maps.${c}apis.com/maps/api/js?`+e;d[q]=f;a.onerror=()=>h=n(Error(p+" could not load."));a.nonce=m.querySelector("script[nonce]")?.nonce||"";m.head.append(a)}));d[l]?'':d[l]=(f,...n)=>r.add(f)&&u().then(()=>d[l](f,...n))})({
          key: "AIzaSyCU0eaC2NP09lZoC3dCA3QTbr7ylauDif8",
          v: "weekly"
        });
      }

      // get token and store in-memory if not set (first time)
      if (!GooglePlacesService._googlePlacesSessionToken) {
        const {AutocompleteSessionToken} = await google.maps.importLibrary("places") as google.maps.PlacesLibrary;
        GooglePlacesService._googlePlacesSessionToken = new AutocompleteSessionToken();
      }
      GooglePlacesService._initialized = true;
    }
  }

  public async importClasses() {
    if (!GooglePlacesService._initialized) {
      await this.initialize();
    }
    return await google.maps.importLibrary("places") as google.maps.PlacesLibrary
  }

  public async sessionToken() {
    if (!GooglePlacesService._initialized) {
      await this.initialize();
    }
    return GooglePlacesService._googlePlacesSessionToken
  }
}
