import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {firstValueFrom, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {CartService} from './cart.service';

@Injectable()
export class CouponService {

  constructor(
    private httpClient: HttpClient) {
  }


  async applyToCart(code) {
    return firstValueFrom<any>(
      this.httpClient
        .put(environment.baseUrls().api + 'coupons/cart/' + code, null)
        .pipe(
          map(shoppingCart => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    );
  }

  async removeFromCart() {
    return firstValueFrom<any>(
      this.httpClient
        .delete(environment.baseUrls().api + 'coupons/cart')
        .pipe(
          map(shoppingCart => {
            CartService.currentCartChanged.next(shoppingCart);
            return shoppingCart;
          })
        )
    )
  }

  async getLoyaltyCoupons() {
    return firstValueFrom<any>(
      this.httpClient.get(environment.baseUrls().api + 'coupons/loyalty')
    );
  }

  async getUserCoupons() {
    return firstValueFrom<any>(
      this.httpClient.get(environment.baseUrls().api + 'coupons/user')
    );
  }

  async verifyLoyaltyCouponEligibility() {
    return firstValueFrom<any>(
      this.httpClient.get(environment.baseUrls().api + 'coupons/loyalty/eligibility', {responseType: 'text'})
    );
  }

  async generateLoyaltyCoupon() {
    return firstValueFrom<any>(
      this.httpClient.post(environment.baseUrls().api + 'coupons/loyalty/generate', null)
    );
  }

  async claimLoyaltyCoupon(claimCode: string, verificationValue: string) {
    return firstValueFrom<any>(
      this.httpClient
        .post(environment.baseUrls().api + 'coupons/loyalty/claim', {
          claimCode: claimCode,
          verificationValue: verificationValue
        })
    );
  }
}
