export class SizeRange {
  step:number;
  max:number;
  min:number;

  constructor(min:number, max:number, step:number=0.125) {
    this.min = min;
    this.max = max;
    this.step = step;
  }

  public get complete():boolean {
    return !isNaN(this.min) && !isNaN(this.max) && !isNaN(this.step);
  }

  private _arr:Array<any>;
  public get asArray():Array<any> {
    if (!this._arr) {
      this._arr = new Array<any>();
      for( var i = this.min; i <= this.max; i+=this.step) {
        this._arr.push({
          active: i ==0,
          value: i
        });
      }
    }
    return this._arr;
  }
}
