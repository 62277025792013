import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {first, firstValueFrom, Observable, of, ReplaySubject} from 'rxjs';
import {catchError, filter, map} from 'rxjs/operators';
import { User } from '../models/user';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import {isPlatformBrowser} from '@angular/common';

@Injectable()
export class SessionService {

  public static currentUserChanged: ReplaySubject<User> = new ReplaySubject(1);
  public static abTestsChanged: ReplaySubject<any> = new ReplaySubject(1);

  private static abTests: any = {
    attemptedOptimizeRegistrations: {}
  };

  protected baseUrl = environment.baseUrls().api + 'session/';

  constructor(private httpClient: HttpClient,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  delete() {
    if (isPlatformBrowser(this.platformId)) {
      return firstValueFrom<any>(
        this.httpClient
          .delete(this.baseUrl)
          .pipe(
            catchError( () => {
              return of(null);
            }),
            map((user: Object) => {
              this.updateCurrentUser(user as User);
              return user as User;
            })
          )
      );
    } else {
      return firstValueFrom<any>(of(null));
    }
  }

  async get() {
    if (isPlatformBrowser(this.platformId)) {
      return firstValueFrom(
        this.httpClient
          .get(this.baseUrl)
          .pipe(
            catchError( () => {
              return of(null);
            }),
            map((user: Object) => {
              this.updateCurrentUser(user as User);
              return user as User;
            })
          )
      );
    } else {
      return firstValueFrom(of(null));
    }
  }

  async post() {
    if (isPlatformBrowser(this.platformId)) {
      return firstValueFrom(
        this.httpClient
          .post(this.baseUrl, null)
          .pipe(
            catchError(() => {
              return of(null);
            }),
            map((user: Object) => {
              this.updateCurrentUser(user as User);
              return user as User;
            })
          )
      );
    } else {
      return firstValueFrom(of(null));
    }
  }

  signIn(email: string, password: string) {
    return firstValueFrom(
      this.httpClient
        .post(this.baseUrl + 'signin', {
          email: email,
          password: password
        })
        .pipe(
          map((user: Object) => {
            this.updateCurrentUser(user as User);
            return user as User;
          })
        )
    );
  }

  async signOutAndGetAnonymousUser() {
    // remove current token
    await this.delete(); // re-get the current user
  }

  updateCurrentUser(user: User): void {
    SessionService.currentUserChanged.next(user);
  }

  public async registerExperimentIfNecessary(variant, name) {
    if (!SessionService.abTests.attemptedOptimizeRegistrations[name + '_' + variant]) {
      SessionService.abTests.attemptedOptimizeRegistrations[name + '_' + variant] = true;
      await firstValueFrom(this.httpClient
        .post(this.baseUrl + 'experiment', {
          name: name,
          variant: variant
        })
        .pipe(
          map((experiment: any) => {
            if (experiment && experiment.name && experiment.variant) {
              if (!SessionService.abTests[experiment.name]) {
                SessionService.abTests[experiment.name] = {};
              }
              if (!SessionService.abTests[experiment.name][experiment.variant]) {
                SessionService.abTests[experiment.name][experiment.variant] = {};
              }
              SessionService.abTestsChanged.next(SessionService.abTests);
            }
            return experiment;
          })
        )
      );
    }
  }
}
