<h1 mat-dialog-title>{{ titles[state] }}</h1>

<mat-dialog-content>
  <div>
    <div class="txt" [innerHtml]="text[state]">
    </div>

    <mat-icon *ngIf="state=='PROCESSING'" class="rotating" svgIcon="spin"></mat-icon>
    <div class="show-progress" *ngIf="percentComplete && percentComplete < 100">
      {{ percentComplete }}%
    </div>

    <div *ngIf="state=='ERROR'" class="fade-in">
      <mat-icon svgIcon="error" color="warning"></mat-icon>
    </div>
    <div *ngIf="state=='DONE'" class="wipe-right">
      <mat-icon svgIcon="check"></mat-icon>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions *ngIf="state=='DONE' && (yesButton || noButton)" class="fx-layout-row"
                    [class]="{
                      'fx-align--space-between-x': noButton && yesButton,
                      'fx-align--center-x': !noButton || !yesButton
                    }"
>

  <button mat-flat-button color="primary" [ngClass]="{'full-width':!noButton}" *ngIf="yesButton" [mat-dialog-close]="true">
    <mat-icon *ngIf="yesButton.icon" [svgIcon]="yesButton.icon"></mat-icon>
    {{ yesButton.text }}
  </button>

  <button mat-flat-button color="secondary" [mat-dialog-close]="false" [ngClass]="{'full-width':!yesButton}" *ngIf="noButton">
    {{ noButton.text }}
    <mat-icon *ngIf="noButton.icon" [svgIcon]="noButton.icon"></mat-icon>
  </button>

</mat-dialog-actions>

<mat-dialog-actions *ngIf="state=='ERROR'" class="fx-layout-row">

  <button mat-flat-button class="full-width" color="primary" [mat-dialog-close]="false">
    Close Window
  </button>
</mat-dialog-actions>
