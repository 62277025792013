import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from "@angular/platform-browser";

@Pipe({
  name: 'sanitizer'
})
export class SanitizerPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: any, args?: any): any {

    if (!value) return null;
    switch (args) {
      case 'style': return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'html': return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'script': return this.sanitizer.bypassSecurityTrustScript(value);
      case 'resource': return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default: return this.sanitizer.bypassSecurityTrustUrl(value);
    }
  }
}
