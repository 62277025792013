<label class="upload-label">
  <input type="file"
         [disabled]="disabled"
         #fileInput
         [multiple]="allowMultiple"
         [accept]="acceptedFileTypes"
         (click)="cancelIfNecessary($event)"
         (change)="change($event)">

  <div>
    <ng-content></ng-content>
  </div>
</label>
