import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DomSanitizer, Meta, SafeResourceUrl, Title} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {WINDOW} from './window.service';
import {ReplaySubject} from 'rxjs';
import Bugsnag from "@bugsnag/js";
import {SessionService} from "./session.service";
import {User} from "../models/user";
import {CookieService} from "../../cookie.service";
import {environment} from "../../../environments/environment";

@Injectable()
export class SeoService {

  private static GLOBAL_META_DATA;
  private static MetaDataSet: ReplaySubject<void> = new ReplaySubject<void>(1);

  public static TitleChanged: ReplaySubject<string> = new ReplaySubject(1);
  private currentUser: User;

  constructor(private meta: Meta, private title: Title, private router: Router,
              private sanitizer: DomSanitizer, @Inject(WINDOW) private window: Window, private cookieService: CookieService,
              @Inject(DOCUMENT) private document: Document, @Inject(PLATFORM_ID) private platformId: Object) {
    SessionService.currentUserChanged.subscribe( (currentUser: User) => {
      this.currentUser = currentUser;
    });
  }

  public init(metaData: any) {
    SeoService.GLOBAL_META_DATA = metaData;
    SeoService.MetaDataSet.next();
  }

  public async ready(): Promise<void> {
    return new Promise(resolve => {
      SeoService.MetaDataSet.subscribe(result => {
        resolve();
      })
    });
  }

  public get currentUrl(): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.document.location.href);
  }

  // was causing massive performance issues so no longer being used
  /*
  activeGoogleOptimize(): void {
    const dataLayer = this.window['dataLayer'];
    if (dataLayer) {
      dataLayer.push({'event': 'optimize.activate'});
    }
  }
  */

  trackAdWordsConversion(order: any): void {
    const gtag = this.window['gtag'];
    if (gtag) {
      gtag('event', 'conversion', {
        'send_to': 'AW-999806077/Lz1qCNOenYQBEP2o39wD',
        'value': order.grandTotal,
        'currency': 'USD',
        'transaction_id': order.confirmationCode
      });
    } else {
      environment.bugSnagApiKey && Bugsnag.notify(new Error("Google Adwords Tracking Failed"), function(event) {
        event.severity = 'error';
        event.context = 'conversions';
        if (this.currentUser != null) {
          event.setUser(this.currentUser.publicId, this.currentUser.email, this.currentUser.fullname);
        }
        event.addMetadata('order', order);
      });
    }
  }

  trackAdWordsRemarketingInfo(pageType: string, productId: string): void {
    const gtag = this.window['gtag'];
    if (gtag) {
      gtag('event', 'page_view', {
        'send_to': 'AW-999806077',
        'ecomm_pagetype': pageType,
        'ecomm_prodid': productId
      });
    }
  }

  normalizeSize(size: Array<number>): string {
    const ui = size[0] + size[1];
    if (ui <= 25) { return 'Small'; }
    if (ui <= 42) { return 'Medium'; }
    return 'Large';
  }

  normalizeOpeningStyle(orderline: any): string {
    let isCustom = false;
    orderline.openings.forEach((op: any) => {
      isCustom = isCustom || op.shape && ((op.shape[0] && op.shape[0].code !== 'rect' && op.shape[0].code !== 'oval') || op.shape.length > 1);
    });
    if (isCustom) { return 'Custom Opening(s)'; }
    if (orderline.openings.length === 0) { return 'No Opening'; }
    if (orderline.openings.length === 1) {
      const op = orderline.openings[0].shape;
      const isOval = op.shape && op.shape.length === 1 && op.shape[0].code === 'oval';
      return 'Single' + (isOval ? 'Oval ' : ' ') + 'Opening';
    }
    if (orderline.openings.length > 1) { return 'Multi Opening'; }
  }

  toTitleCase(str: string): string {
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substring(1).toLowerCase();
      }
    );
  }

  createGAItemsFromOrderline(orderline: any): any {
    const items = [];
    if (orderline.primaryProduct === 'SAMPLE') {
      orderline.samples.forEach((sample: any) => {
        items.push({
          item_id: sample.code + '-SMPL',
          item_name: sample.description + ' Sample',
          item_category: this.toTitleCase(sample.type) + ' Sample',
          price: 1,
          quantity: 1
        });
      });
    } else if (orderline.primaryProduct === 'GC') {
      items.push({
        item_id: 'GC-' + orderline.preConfiguredProductId,
        item_name: orderline.description,
        item_category: 'Gift Card',
        price: orderline.price,
        quantity: orderline.qty
      });
    } else {
      const openingStyle = this.normalizeOpeningStyle(orderline);
      const size = this.normalizeSize( orderline.OuterSizeNumeric);
      ['topMat', 'bottomMat', 'moulding', 'glazing', 'backingBoard', 'bag'].forEach((part) => {
        const isMat = part.indexOf('Mat') > -1;
        if (orderline[part]) {
          const item: any = {
            item_id: orderline[part].code,
            item_name: orderline[part].description,
            price: orderline.priceParts[part],
            quantity: orderline.qty,
            item_category: part === 'bag' ? 'Show Bag' : (isMat ? 'Matboard' : this.toTitleCase(orderline[part].categoryType))
          };
          if (isMat) {
            item.item_category2 = this.toTitleCase(orderline[part].quality) + ' Quality';
            item.item_category3 = size;
            item.item_category4 = openingStyle;
            item.item_category5 = this.toTitleCase(orderline[part].coreType) + ' Core';
          } else if (part !== 'bag') {
            item.item_category2 = orderline[part].categoryName.trim();
            item.item_category3 = size;
          }
          items.push(item);
        }
      });

      if (orderline.priceParts.custom > 0) {
        items.push({
          item_id: 'CUSTOM_DESIGN',
          item_name: 'Customization & Design',
          price: orderline.priceParts.custom
        });
      }

      if (orderline.priceParts.openingShapes > 0) {
        items.push({
          item_id: 'CUSTOM_OPENING',
          item_name: 'Custom Opening',
          price: orderline.priceParts.openingShapes
        });
      }
    }

    return items;
  }

  setUserDataForGoogleTrackers(user: User): void {
    const gtag = this.window['gtag'];
    if (gtag && user != null) {
      gtag('set', 'user_data', {
        "email": user.email
      });
    }
  }

  trackGAEcommEvent(eventName: string, dataSource: any): void {
    const gtag = this.window['gtag'];
    if (gtag) {
      let items = [];
      // data is a cart
      if (typeof dataSource.orderlines !== 'undefined') {
        dataSource.orderlines.forEach(ol => {
          items = items.concat(this.createGAItemsFromOrderline(ol));
        });
      // must be a order line
      } else {
        items = items.concat(this.createGAItemsFromOrderline(dataSource));
      }

      // add shipping and tax as items so that revenue numbers line up better
      if (typeof dataSource.tax !== 'undefined') {
        items.push({
          item_id: 'TAX',
          item_name: 'Tax Collected',
          price: dataSource.tax,
          quantity: 1,
          item_category: 'Tax'
        });
      }
      if (typeof dataSource.shippingTotal !== 'undefined') {
        items.push({
          item_id: 'SHIPPING',
          item_name: 'Shipping Collected',
          price: dataSource.shippingTotal,
          quantity: 1,
          item_category: 'Shipping'
        });
      }

      const data: any = {
        currency: 'USD',
        items: items
      };
      if (typeof dataSource.confirmationCode !== 'undefined') { data.transaction_id = dataSource.confirmationCode; }
      if (typeof dataSource.grandTotal !== 'undefined') { data.value = dataSource.grandTotal; }
      if (typeof dataSource.shippingTotal !== 'undefined') { data.shipping = dataSource.shippingTotal; }
      if (typeof dataSource.coupon !== 'undefined' && typeof dataSource.coupon.Code !== 'undefined') { data.coupon = dataSource.coupon.Code; }
      if (typeof dataSource.tax !== 'undefined') { data.tax = dataSource.tax; }

      gtag('event', eventName, data);
    } else {
      environment.bugSnagApiKey && Bugsnag.notify(new Error("Google Analytics Tracking of '" + eventName + "' Failed"), function(event) {
        event.severity = 'error';
        event.context = 'conversions';
        if (this.currentUser != null) {
          event.setUser(this.currentUser.publicId, this.currentUser.email, this.currentUser.fullname);
        }
        event.addMetadata('order', dataSource);
      });
    }
  }

  trackBingConversion(order: any) {
    const uetq = this.window['uetq'] ? this.window['uetq'] : [];
    uetq.push({ 'ec': 'conversion', 'ea': 'purchase', 'el': 'track', 'ev': 1, 'gv': order.grandTotal, 'gc': 'USD'} );
  }

  trackFBConversion(order: any) {
    const fbq = this.window['fbq'];
    if (fbq) {
      fbq('track', 'Purchase', {'value': order.grandTotal, 'currency': 'USD'});
    }
  }

  trackImpactConversion(order: any) {
    const ire = this.window['ire'];
    if (ire) {
      let ireItems = function(orderlines: any[]) {
        return orderlines.map(ol => {
          return {
            subTotal: ol.price * ol.qty,
            category: ol.primaryProduct,
            sku: ol.id,
            quantity: ol.qty
          }
        })
      };

      ire('trackConversion', 37588, {
          orderId: order.confirmationCode,
          customerId: order.customer.publicId,
          customerEmail: order.customer.encryptedEmail,
          customerStatus: order.customer.status,
          currencyCode: 'USD',
          orderPromoCode: order.coupon ? order.coupon.Code : '',
          orderDiscount: order.discount,
          items: ireItems(order.orderlines)
        },
        {
          verifySiteDefinitionMatch:true
        }
      );
    }
  }

  trackShareASaleConversion(order: any) {
    if (isPlatformBrowser(this.platformId)) {
      const shareASaleCookie = this.cookieService.get('sas_m_awin');
      const shareASaleCookieAsJSON = shareASaleCookie ? JSON.parse(shareASaleCookie) : null;
      const shareASaleClickId = shareASaleCookieAsJSON ? shareASaleCookieAsJSON.clickId : '';
      const shareASaleImageElement = this.document.createElement('img');
      shareASaleImageElement.width = 1;
      shareASaleImageElement.height = 1;
      shareASaleImageElement.src = 'https://www.shareasale.com/sale.cfm?tracking=' + order.confirmationCode + '&amount=' + order.subTotal + '&couponcode=' + (order.coupon ? order.coupon.Code : '') + '&sscid=' + shareASaleClickId + '&merchantID=140941&transtype=sale&sscidmode=6';
      this.document.body.appendChild(shareASaleImageElement)
    }
  }

  private setCanonicalLink(url: string): void {
    const canonicalTag = this.meta.getTag('rel="canonical"');
    if (canonicalTag) {
      this.meta.updateTag({ rel: 'canonical', href: url });
    } else {
      this.meta.addTag({ rel: 'canonical', href: url });
    }
  }

  private setTitle(title: string, token: string = ''): void {
    title = title.replace('__TOKEN__', token);
    this.title.setTitle(title);
    this.setOpenGraphProperty('title', title)
    SeoService.TitleChanged.next(title);
  }

  private setDescription(description: string, token: string = ''):void {
    description = description.replace('__TOKEN__', token);
    this.meta.updateTag({name: 'description', content: description});
    this.setOpenGraphProperty('description', description);
  }

  private setOpenGraphProperty(prop: string, content): void {
    this.meta.updateTag({property: 'og:' + prop, content: content});
  }

  public setMetaData(data: any) {
    if (data.title) this.setTitle(data.title, data.titleToken ? data.titleToken : '');
    if (data.description) this.setDescription(data.description, data.descriptionToken ? data.descriptionToken : '');
    if (data.og) {
      for (let prop in data.og) {
        if (data.og[prop]) this.setOpenGraphProperty(prop, data.og[prop]);
      }
    }
    if (data.link) this.setCanonicalLink(data.link);
  }

  public getMetaDataByUrl(url: string = null): any {
    if (!SeoService.GLOBAL_META_DATA) {
      throw new Error("Meta Data Not Loaded.  Did you wait for ready to complete?");
    }

    if (url == null) {
      url = this.router.url;
    }

    const parts = url.split('/').slice(1);
    let key = parts.join('-');
    key = key == '' ? 'index' : key;

    let descriptionToken = '';
    let titleToken = '';

    if (key.startsWith('edit')) {
      key = 'edit';
    }

    if (key.startsWith('account-order')) {
      const keyParts = key.split('-');
      titleToken = keyParts[keyParts.length - 1];
      key = 'account-order';
    }

    function convertText(str: string): string {
      return str
        .replace(/_/g, '-')
        .split('-')
        .map( word => {
          // special case(s)
          if (word === 'mdf') return 'MDF';
          return word[0].toUpperCase() + word.substring(1).toLowerCase();
        })
        .join(' ');
    }

    if (key.startsWith('buy-custom-picture-frames-')) {
      descriptionToken = convertText(key.replace('buy-custom-picture-frames-',''));
      titleToken = descriptionToken;
      key = 'buy-custom-picture-frames-deep';
    }

    if (key.startsWith('buy-matboards-')) {
      descriptionToken = convertText(key.replace('buy-matboards-',''));
      titleToken = descriptionToken;
      key = 'buy-matboards-deep';
    }

    let metaData = SeoService.GLOBAL_META_DATA && SeoService.GLOBAL_META_DATA[key] ? SeoService.GLOBAL_META_DATA[key] : null;

    return metaData ? {
      title: metaData.title,
      titleToken: titleToken,
      description: metaData.description,
      descriptionToken: descriptionToken,
      link: url,
      og: {
        url: url,
        image: metaData.image ? environment.baseUrls().site + metaData.image : null
      }
    } : null;
  }
}
