import { Component, OnInit, OnChanges, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import {BreakpointObserver} from "@angular/cdk/layout";
import {BREAKPOINTS} from "../../constants/breakpoints";

@Component({
  selector: 'mbnm-navigation-tiles',
  templateUrl: './navigation-tiles.component.html',
  styleUrls: ['./navigation-tiles.component.scss']
})
export class NavigationTilesComponent implements OnInit, OnChanges {

  @Input()
  mode:string = '';

  @Input()
  linkStyle:string = 'text';

  @Input()
  enableHero:boolean= false;

  @Input()
  tileCollection:Array<any>;

  @Input()
  columns:number = 3;

  @Output()
  tileClick: EventEmitter<number> = new EventEmitter<number>();

  watcher: Subscription;
  activeMediaQuery:string;
  distributedTiles:Array<Array<any>>;
  heroTile:any;

  ngOnInit() {
  }

  ngOnChanges(changes:SimpleChanges) {
    if (changes['tileCollection'] && changes['tileCollection'].currentValue) {
      if (this.tileCollection) {
        let tiles = this.tileCollection.slice();
        if (this.enableHero) {
          this.heroTile = tiles.shift();
        }
        this.distributedTiles = this.distributeData(tiles, this.columns);
      }
    }
  }

  constructor(public media: BreakpointObserver) {
  }

  private distributeData(a:Array<any>, n:number):Array<any> {

    // short-circuit if bad data passed in
    if (typeof a === 'undefined' || n < 2) return a;

    let len = a.length,
      out = [];

    let distribution = this.media.isMatched(BREAKPOINTS.Standard.XSmall) ? 'columns' : 'rows';

    switch (distribution)
    {
      case 'columns': {
        let i = 0;
        while (i < len) {
          let size = Math.ceil((len - i) / n--);
          out.push(a.slice(i, i += size));
        }
      } break;

      case ('rows'): {
        for (let i=0; i < n; i++) {
          out.push([]);
        }

        for (let i=0; i < len; i++) {
          out[i % n].push(a[i]);
        }
      }
    }

    return out;
  }

  tileClicked(tile:any) {
    this.tileClick.emit(tile);
  }
}
