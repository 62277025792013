<mbnm-navigation-tile *ngIf="heroTile" [tile]="heroTile" mode="hero"></mbnm-navigation-tile>

<div class="fx-layout-row fx-layout-wrap
            fx-align--x-space-between fx-align--center-x
            fx-gap--32px">
  @for (tiles of distributedTiles; track tiles) {
    @for (tile of tiles; track tile) {
      <mbnm-navigation-tile class="fx-flex--30--gt-sm fx-flex--45--sm fx-flex--100--xs fx-gap--16px"
                            [tile]="tile" [mode]="mode" [linkStyle]="linkStyle" />
    }
  }

</div>
