import {Component, Input, OnInit} from '@angular/core';
import {WaitModalComponent} from '../../../wait-modal/wait-modal.component';
import {CartService} from '../../services/cart.service';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'mbnm-promotion',
  templateUrl: './promotion.component.html',
  styleUrls: ['./promotion.component.scss']
})
export class PromotionComponent implements OnInit {

  constructor(public cartService:CartService, public dialog:MatDialog) { }

  @Input()
  shoppingCart:any;

  ngOnInit() {
  }

  async savePromotionalItemToCart(preConfiguredProductId) {
    let dialogRef = this.dialog.open(WaitModalComponent, {});

    let instance = dialogRef.componentInstance;
    dialogRef.disableClose = true;
    instance.state = 'PROCESSING';

    instance.titles = {
      PROCESSING: 'Saving to Cart...',
      ERROR: 'Error Saving to Cart!',
      DONE: 'Saved to Cart'
    };

    instance.text = {
      PROCESSING: 'Please wait while we save your promotional item to the cart.',
      ERROR: 'Something went wrong saving your item!',
      DONE: 'Congrats! Your promotional item has been saved to the cart.'
    };

    try {
      await this.cartService.addItemsToCart([{
        preConfiguredProductId: preConfiguredProductId
      }]);
      instance.state = 'DONE';
      setTimeout( () => {
        dialogRef.close();
      }, 1000);
    } catch {
      instance.state = 'ERROR';
    }
  }
}
