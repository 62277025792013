<ng-container [ngSwitch]="mode">
  <div class="tile image fx-layout-column fx-gap--8px" *ngSwitchCase="'image'">

    <a [routerLink]="tile.link" [title]="tile.title">
      <img [alt]="tile.title" loading="lazy" [src]="tile.image" />
    </a>

    <div *ngIf="tile.title" class="title fx-align--center-x" [innerHtml]="tile.title"></div>

    <div *ngIf="tile.caption" class="caption" [innerHtml]="tile.caption"></div>
  </div>

  <div class="tile hero" *ngSwitchCase="'hero'">

    <div *ngIf="tile.image" >

      <a [routerLink]="tile.link" *ngIf="tile.title" class="title large hide-xs" [innerHtml]="tile.title"></a>

      <a [routerLink]="tile.link" [title]="tile.title">
        <img loading="lazy" [src]="tile.image" [alt]="tile.title" />
      </a>
    </div>

    <div>
      <a [routerLink]="tile.link" *ngIf="tile.title" class="title large hide-xs" [innerHtml]="tile.title"></a>

      <div *ngIf="tile.caption" class="caption" [innerHtml]="tile.caption"></div>

      <div class="read-more">
        <button mat-flat-button color="primary">
          <a [routerLink]="tile.link">
            READ MORE
          </a>
        </button>
      </div>
    </div>
  </div>

  <div class="tile" *ngSwitchCase="'list'">
    <a [routerLink]="tile.link" *ngIf="tile.title" [title]="tile.title" class="title" [innerHtml]="tile.title"></a>
    <div *ngIf="tile.caption" class="caption" [innerHtml]="tile.caption"></div>
    <a [routerLink]="tile.link" class="read-more">read more &raquo;</a>
  </div>

  <div class="icon sm" *ngSwitchCase="'icon'">
    <div class="title-block">
        <mat-icon [svgIcon]="tile.icon" class="main-help-icon "></mat-icon>
        <a [routerLink]="tile.link" *ngIf="tile.title" class="main-help-title" [innerHtml]="tile.title"></a>
    </div>

    <div *ngIf="tile.caption" class="help-caption" [innerHtml]="tile.caption"></div>

  </div>

  <div class="tile fx-layout-column fx-gap--16px" *ngSwitchDefault>

    <div class="heading fx-layout-row fx-gap--16px fx-align--x-center">
      <button mat-icon-button color="secondary" [routerLink]="tile.link" *ngIf="tile.title && tile.link" class="title" [ngClass]="{'title-center': tile.icon}">
        <mat-icon *ngIf="tile.icon" [svgIcon]="tile.icon" ></mat-icon>
      </button>
      <h3 [innerHtml]="tile.title" class="fx-flex-grow"></h3>
    </div>

    <div></div>

    <a [routerLink]="tile.link" [title]="tile.title" *ngIf="tile.image">
      <img loading="lazy" [src]="tile.image" [alt]="tile.title" />
    </a>

    <div *ngIf="tile.caption" class="caption" [innerHtml]="tile.caption"></div>

    <div class="fx-flex-grow"></div>

    <div *ngIf="tile.link" class="read-more fx-align--end-x">
      <button mat-stroked-button *ngIf="linkStyle ==='text'" color="secondary">
        <a [routerLink]="tile.link">
          READ MORE
        </a>
      </button>
      <button mat-flat-button *ngIf="linkStyle ==='button'" color="secondary">
        <a [routerLink]="tile.link">
          READ MORE
        </a>
      </button>
    </div>

    <div></div>

  </div>
</ng-container>
