import { Pipe, PipeTransform } from '@angular/core';
import {SizeService} from "../services/size.service";

@Pipe({
  name: 'size'
})
export class SizePipe implements PipeTransform {

  sizeService: SizeService;
  constructor() {
    this.sizeService = new SizeService();
  }

  transform(value: any, args?: any): any {
    const ret = [];
    if (!value) value = '';

    const parts = Array.isArray(value) ? value : value.toString().trim().split('x');

    parts.forEach( (part) => {
      const val = this.sizeService.evalFraction(part);
      if (args === 'multichar' && val < 1) {
        ret.push(this.sizeService.fractionAsStringMultiChar(val));
      } else {
        ret.push(this.sizeService.fractionAsString(val));
      }
    });

    if (ret.length === 2) {
      return ret.join(' x ');
    } else {
      return ret[0];
    }
  }
}
