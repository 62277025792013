import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {isPlatformServer} from "@angular/common";

@Component({
  selector: 'mbnm-large-image-dialog',
  templateUrl: './large-image-dialog.component.html',
  styleUrls: ['./large-image-dialog.component.scss']
})
export class LargeImageDialogComponent implements OnInit {

  constructor(private dialogRef:MatDialogRef<LargeImageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any,
              @Inject(PLATFORM_ID) private platformId) { }

  imageLoaded:boolean;
  public platformIsServer = false;

  ngOnInit() {
    this.platformIsServer = isPlatformServer(this.platformId);
  }

  close() {
    this.dialogRef.close();
  }
}
