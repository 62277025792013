import {Inject, Injectable, Optional, PLATFORM_ID} from '@angular/core';
import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import { REQUEST } from "../express.tokens";
import { Request } from 'express';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  private cookieStore: any = {};

  private parseCookies(cookies) {
    this.cookieStore = {};
    if (!!cookies === false) { return; }
    let cookiesArr = cookies.split(';');
    for (const cookie of cookiesArr) {
      const cookieArr = cookie.split('=');
      this.cookieStore[cookieArr[0].trim()] = cookieArr[1].trim();
    }
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Optional() @Inject(REQUEST) private request: Request
  ) {
    let cookiesStr = '';
    if (this.request) {
      cookiesStr  = this.request.cookies;
    } else if (this.document) {
      try {
        cookiesStr = this.document.cookie;
      } catch {
      }
    }
    this.parseCookies(cookiesStr);
  }

  public get(key: string): string {
    this.errorIfServer();
    return this.cookieStore[key];
  }

  public remove(key: string): void {
    this.errorIfServer();
    delete this.cookieStore[key];
    this.document.cookie = key + '=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
  }

  public put(key: string, value: string, options: any = {}): void {
    this.errorIfServer();
    this.cookieStore[key] = value;
    this.document.cookie = key + '=' + value + ";path=/";
  }

  private errorIfServer(): void {
    // even though this technically supports back-end cookies, due to use of pre-rendering we will not be using it
    if (!isPlatformBrowser(this.platformId))
    {
      throw new Error('Cookie Service Called from Server Side.  We use Pre-render so should never happen.')
    }
  }
}
