import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {firstValueFrom, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import {environment} from '../../../environments/environment';

@Injectable()
export class OrderService {
  private baseUrl = environment.baseUrls().api;
  constructor(private httpClient: HttpClient) {}

  getOrders() {
    return firstValueFrom<any>(
      this.httpClient
        .get(this.baseUrl + 'user/orders')
        .pipe(
          map(orders => {
            return Array.isArray(orders) ? orders : [orders];
          })
        )
    );
  }

  getOrderById(orderId: string) {
    return firstValueFrom<any>(
      this.httpClient
        .get(this.baseUrl + 'user/order/' + orderId)
    );
  }

  editOrder(orderId: string) {
    const headers = new HttpHeaders({'X-MBNM-ACTION': 'LOAD_ORDER_FOR_EDIT'});
    return firstValueFrom<any>(
      this.httpClient
        .patch(this.baseUrl + 'user/order/' + orderId, null, { headers: headers })
    );
  }

  cancelEditOrder(orderId: string) {
    const headers = new HttpHeaders({ 'X-MBNM-ACTION': 'CANCEL_ORDER_EDIT' });
    return firstValueFrom<any>(
      this.httpClient
        .patch(this.baseUrl + 'user/order/' + orderId, null, { headers: headers })
    );
  }

  markAsTracked(orderId: string) {
    const headers = new HttpHeaders({ 'X-MBNM-ACTION': 'MARK_ORDER_AS_TRACKED' });
    return firstValueFrom<any>(
      this.httpClient
        .patch(this.baseUrl + 'user/order/' + orderId, null, { headers: headers })
    );
  }


  cancelOrder(orderId: string) {
    const headers = new HttpHeaders({ 'X-MBNM-ACTION': 'CANCEL_ORDER' });
    return firstValueFrom<any>(
      this.httpClient
        .patch(this.baseUrl + 'user/order/' + orderId, null, { headers: headers })
    );
  }

  sendOrderMessage(confirmationCode: string, message) {
    return firstValueFrom<any>(
      this.httpClient
        .post(this.baseUrl + 'user/order/' + confirmationCode  + '/message', message)
    );
  }
}
