import { Pipe } from '@angular/core';
import {CurrencyPipe} from '@angular/common';

@Pipe({
  name: 'currency2'
})
export class Currency2Pipe extends CurrencyPipe {

  transform(value: any, currencyCode: string = 'USD', display:any = 'symbol'): any {
    if (Math.abs(value) >= 1 || value == 0) {
      let currency = super.transform(value, currencyCode, display);
      return currency && currency !== 'NaN' ? (currency.replace('.','<sup>.') + '</sup>') : '$0<sup>.00</sup>';
    } else if (currencyCode == 'USD') {
      let cents = Math.round((value ? value : 0) * 100);
      return cents + '<sup>¢</sup>';
    }
    return value;
  }
}
