<mat-spinner color="secondary" *ngIf="!platformIsServer && !imageLoaded" class="center"></mat-spinner>
<div class="close-button">
  <button mat-icon-button mat-dialog-close>
    <mat-icon svgIcon="x" />
  </button>
</div>

<img [src]="data.largeImage" (load)="imageLoaded = true;" [alt]="data.alt" (click)="close()"/>

<div class="title">
  {{ data.title }}
</div>
