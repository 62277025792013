import {Directive, HostListener, Inject, Input} from '@angular/core';
import {WINDOW} from '../services/window.service';

@Directive({
  selector: '[mbnmQty]'
})
export class QtyDirective {

  constructor(@Inject(WINDOW) private window: Window) { }

  @Input('mbnmQty')
  maxLength: string;

  @HostListener('keydown', ['$event']) onKeyDown(event) {
    const e = <KeyboardEvent> event;

    const input = e.currentTarget as HTMLInputElement;
    const selection = this.window.getSelection().toString();
    const isHighlighted = selection.length > 0 && input.value.indexOf(selection) >= 0;
    const maxReached = input && input.value ? input.value.length >= parseInt(this.maxLength ? this.maxLength : '4', 0) : false;
    const keyCode = e.keyCode;
    if ([46, 8, 9, 27, 13].indexOf(keyCode) !== -1 ||
      // Allow: Ctrl+A
      (keyCode === 65 && e.ctrlKey === true) ||
      // Allow: Ctrl+C
      (keyCode === 67 && e.ctrlKey === true) ||
      // Allow: Ctrl+V
      (keyCode === 86 && e.ctrlKey === true) ||
      // Allow: Ctrl+X
      (keyCode === 88 && e.ctrlKey === true) ||
      // Allow digits
      (keyCode >= 48 && keyCode <= 57 && (isHighlighted || !maxReached) && e.shiftKey === false && (typeof e.key === 'undefined' || !isNaN(parseInt(e.key, 0)))) ||
      // Allow numpad digits
      (keyCode >= 96 && keyCode <= 105 && (isHighlighted || !maxReached) && e.shiftKey === false) ||
      // Allow: home, end, left, right, up, down
      (keyCode >= 35 && keyCode <= 40)) {
      // let it happen, don't do anything
      return;
    }

    e.preventDefault();
  }
}
