import {Component, OnInit, Input, Inject, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from "@angular/common";

@Component({
  selector: 'mbnm-navigation-tile',
  templateUrl: './navigation-tile.component.html',
  styleUrls: ['./navigation-tile.component.scss']
})
export class NavigationTileComponent implements OnInit {

  @Input()
  tile: any;

  @Input()
  mode = '';

  @Input()
  linkStyle = 'text';

  constructor(@Inject(PLATFORM_ID) private platformId) { }

  public platformIsServer = false;
  ngOnInit() {
    this.platformIsServer = isPlatformServer(this.platformId);
  }
}
