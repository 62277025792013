import {Component, EventEmitter, Input, OnInit} from '@angular/core';
import {Project} from '../../shared/models/project';
import {MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'mbnm-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss']
})
export class UploadDialogComponent implements OnInit {
  @Input() data: any;

  constructor(private dialogRef: MatDialogRef<UploadDialogComponent>) { }

  public acceptedFileTypes:string = 'image/*';
  public uploadPath = '';
  public allowMultiple:boolean = false;

  public uploadComplete: EventEmitter<any> = new EventEmitter<any>();
  public uploadFailed: EventEmitter<any> = new EventEmitter<any>();
  ngOnInit() {
  }

  onUploadComplete(result: any): void {
    this.uploadComplete.emit(result);
    this.dialogRef.close(result);
  }

  onUploadFailed(result: any): void {
    this.uploadFailed.emit(result);
    this.dialogRef.close(result);
  }
}
