export class User {
  email: string;
  phonenumber: string;
  fullname: string;
  password: string;
  subscriptions: any;
  forgotPasswordSecret: string;
  isGuest: boolean;
  isAnonymous: boolean;
  currentToken: string;
  impersonating: boolean;
  testGroup: string;
  publicId: string;

  constructor(json: any) {
    if (json) {
      this.email = json.email;
      this.fullname = json.fullname;
      this.phonenumber = json.phonenumber;
      this.subscriptions = json.subscriptions;
      this.forgotPasswordSecret = json.forgotPasswordSecret;
      this.isGuest = json.isGuest;
      this.isAnonymous = json.isAnonymous;
      this.currentToken = json.currentToken;
      this.testGroup = json.testGroup;
      this.publicId = json.publicId;
    }
  }
}
