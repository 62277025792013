import {Injectable} from '@angular/core';
import {MatSnackBar, MatSnackBarVerticalPosition} from '@angular/material/snack-bar';
import {ComponentType} from '@angular/cdk/portal';
import {BreakpointObserver} from "@angular/cdk/layout";
import {BREAKPOINTS} from "../constants/breakpoints";

@Injectable()
export class NotificationService {

  constructor(private snackBar: MatSnackBar, private media: BreakpointObserver) { }

  public createNotification(message: string, type: string = 'warn', duration: number = 60000, verticalPosition: MatSnackBarVerticalPosition = 'top'): void {
    const panelClass = [type];
    let action = 'dismiss';
    if (this.media.isMatched(BREAKPOINTS.Standard.XSmall)) {
      panelClass.push('xs');
      action = 'X';
    }

    this.snackBar.open(message, action, {duration: duration, verticalPosition: verticalPosition, panelClass: panelClass});
  }
}
