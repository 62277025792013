<div *ngIf="shoppingCart && shoppingCart.promotion && (!shoppingCart.promotion.Expired && (!shoppingCart.promotion.ConditionsMet || !shoppingCart.promotion.AlreadyInCart) || (shoppingCart.promotion.AlreadyInCart && shoppingCart.promotion.Expired))">
  <h3 [ngClass]="{'button-visible': shoppingCart.promotion.ConditionsMet && !shoppingCart.promotion.AlreadyInCart && !shoppingCart.promotion.Expired}">{{ shoppingCart.promotion.Title }}</h3>
  <p>
    <ng-container *ngIf="shoppingCart.promotion.AlreadyInCart && shoppingCart.promotion.Expired">
      <strong>This promotion has expired.</strong>
      Please remove the promotional item from your cart to complete your order.
    </ng-container>
    <ng-container *ngIf="shoppingCart.promotion.Type == 'SUBTOTAL' && !shoppingCart.promotion.ConditionsMet && !shoppingCart.promotion.AlreadyInCart && !shoppingCart.promotion.Expired">
      Spend <strong [innerHtml]="shoppingCart.promotion.Threshold - shoppingCart.subTotal | currency2"></strong> more and get your
      <u>{{ shoppingCart.promotion.Product.title }}</u> for <strong [innerHtml]="shoppingCart.promotion.Product.price ? (shoppingCart.promotion.Product.price | currency2) : 'FREE'"></strong>
    </ng-container>
    <ng-container *ngIf="shoppingCart.promotion.Type == 'SUBTOTAL' && !shoppingCart.promotion.ConditionsMet && shoppingCart.promotion.AlreadyInCart && !shoppingCart.promotion.Expired">
      <strong>You no longer meet the conditions for your free item!</strong>
      Please spend <strong [innerHtml]="shoppingCart.promotion.Threshold - shoppingCart.subTotal | currency2"></strong> more to re-qualify to get your
      <u>{{ shoppingCart.promotion.Product.title }}</u> for <strong [innerHtml]="shoppingCart.promotion.Product.price ? (shoppingCart.promotion.Product.price | currency2) : 'FREE'"></strong>
    </ng-container>
    <ng-container *ngIf="shoppingCart.promotion.ConditionsMet && !shoppingCart.promotion.Expired">
      Congrats! You've earned your <u>{{ shoppingCart.promotion.Product.title }}</u> for <strong [innerHtml]="shoppingCart.promotion.Product.price ? (shoppingCart.promotion.Product.price | currency2) : 'FREE'"></strong> with your order.
      <ng-container *ngIf="!shoppingCart.promotion.AlreadyInCart">Claim yours now!</ng-container>
    </ng-container>
  </p>
  <button mat-flat-button="" color="primary"
          *ngIf="shoppingCart.promotion.ConditionsMet && !shoppingCart.promotion.AlreadyInCart && !shoppingCart.promotion.Expired"
          (click)="savePromotionalItemToCart(shoppingCart.promotion.Product.id)">
    Claim Reward
    <mat-icon svgIcon="check"></mat-icon>
  </button>
</div>
