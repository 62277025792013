import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stringFormat'
})
export class StringFormatPipe implements PipeTransform {

  transform(data: any, format: string): any {

    let pieces = [];

    let before, value, key, start, end, position = 0, trail;

    while (true) {
      start = format.indexOf('{', position);
      end = format.indexOf('}', position);

      if (start === -1 || end === -1) {
        // Insert trail piece.
        trail = format.substr(position);
        if (trail !== "") {
          pieces.push(trail);
        }
        break;
      }

      key = format.substring(start + 1, end);
      value = String(data[key]);

      before = format.substring(position, start);
      pieces.push(before);

      pieces.push(value);

      position = end + 1;
    }

    var result = pieces.join("");
    return result;
  }
}
