import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'inches'
})
export class InchesPipe implements PipeTransform {

  private almostEq = (a, b): boolean => Math.abs(a - b) <= 9.5367432e-7;
  private GCD = (a, b): any => this.almostEq(b, 0) ? a : this.GCD(b, a % b);

  private findPrecision = (n) => {
    let  e = 1
    while (!this.almostEq(Math.round(n * e) / e, n)) {
      e *= 10
    }
    return e;
  };

  private num2fraction = (num) => {
    if (num === Infinity) return '';
    if (num === 0 || num === '0') return '0';
    if (typeof num === 'string') {
      num = parseFloat(num).toString();
    }
    const precision = this.findPrecision(num);
    const number = num * precision;
    const gcd = Math.abs(this.GCD(number, precision));
    const numerator = number / gcd;
    const denominator = precision / gcd;
    return Math.round(numerator) + '/' + Math.round(denominator);
  };

  transform(value: any): any {
    value = value.toString();
    let val = value;
    // the match regex finds a number followed by a space and an x
    // then any string ending with a number

    const vals = value.toLowerCase().split('x');

    for (let i = 0; i < vals.length; i++) {
      // trims trailing and leading whitespace
      // then adds a double quote after the numbers
      vals[i] = vals[i].replace(/^\s+|\s+$/g, '') + '”';
    }

    if (vals.length === 1) {
      if (vals[0].indexOf('.') === -1) {
        val = vals[0];
      } else if (parseInt(vals[0], 10) >= 1) {
        let frac = this.num2fraction(vals[0].split('.')[1]);
        if (frac.endsWith('/1')) {
          val = vals[0];
        } else {
          val = vals[0].split('.')[0] + ' ' + this.num2fraction(vals[0].split('.')[1]) + '”';
        }
      } else {
        val = this.num2fraction(vals[0]) + '”';
      }
    } else {
      val = vals.join(' x ');
    }

    return val;
  }
}
