import {Opening} from './opening';
import {Size} from './size';

export class Design {
  public id: string;
  public openings: Array<Opening>;
  public outerSize: Size;
  public restrictions: any;
  public designType: string;
  public vgrooves: Array<any>;

  constructor(json: any = null) {
    if (json !== null) {
      this.id = json.id;
      this.openings = json.openings ? json.openings : [];
      this.outerSize = json.outerSize;
      this.restrictions = json.restrictions;
      this.designType = json.designType ? json.designType : 'simple';
      this.vgrooves = json.vgrooves ? json.vgrooves : []
    }
  }
}
